import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button } from '..';
import { Input } from '..';
import { loginInfoReset, loginWithEmail, setInputEmail } from '../../store/actions/auth';

function SignInForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const loginAction = useSelector(state => state.auth.login);

  const onContinueClick = () => {
    if (!email) {
      setError('Email field must be filled.');
      return;
    }
    if (!password) {
      setError('Password field must be filled.');
      return;
    }
    setError('');
    dispatch(loginWithEmail(email, password));
  };

  useEffect(() => {
    if (loginAction.success) {
      dispatch(loginInfoReset());
      history.push('/auth/phone-verification');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginAction, history, dispatch]);

  useEffect(() => {
    if (loginAction.error) {
      if (loginAction.error.message === "User's email isn't verified.") {
        dispatch(loginInfoReset());
        dispatch(setInputEmail(email));
        history.push('/auth/email-verification');
      } else if (loginAction.error.message === "User's phone is not defined yet!") {
        dispatch(loginInfoReset());
        dispatch(setInputEmail(email));
        history.push('/auth/phone-setup');
      } else {
        dispatch(loginInfoReset());
        setError(loginAction.error.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginAction, history, dispatch]);

  return (
    <>
      <p className="auth__screen__container-text">Sign in to your account</p>
      <Input type="text" className="auth__screen__container-input" placeholder="Email" onChange={setEmail} />
      <Input type="password" className="auth__screen__container-input" placeholder="Password" onChange={setPassword} />
      <div style={{ color: 'white' }}>{error}</div>
      <Button onClick={onContinueClick} title="Continue" className="auth__screen__container-button" />
    </>
  );
}

export default SignInForm;
