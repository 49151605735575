export const LOCAL_STORAGE_ACCESS_TOKEN = 'EXPANSION_UNLIMITED_ACCESS_TOKEN';
export const LOCAL_STORAGE_REFRESH_TOKEN = 'EXPANSION_UNLIMITED_REFRESH_TOKEN';

export const userReducerType = {
  id: 'id',
  username: 'username',
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  balance: 'balance',
  phone: 'phone',
  profilePhoto: 'profilePhoto',
};

export const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export const _REFERRAL_CODE_DISCOUNT = 0.05;
