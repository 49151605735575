import {
  FETCH_USER_INFO_SUCCESS,
  FETCH_USER_INFO_ERROR,
  POST_USERS_INFO_ATTEMPT,
  POST_USERS_INFO_ERROR,
  POST_USERS_INFO_RESET,
  POST_USERS_INFO_SUCCESS,
  SET_USER_INFO,
  UPLOAD_PROFILE_PHOTO_ATTEMPT,
  UPLOAD_PROFILE_PHOTO_SUCCESS,
  UPLOAD_PROFILE_PHOTO_ERROR,
  UPLOAD_PROFILE_PHOTO_RESET,
  SET_USERS_ALL_INFO,
  DECREASE_BALANCE,
  UPDATE_PROFILE_ATTEMPT,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_RESET,
  RESET_PASSWORD_ATTEMPT,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_RESET,
  WITHDRAW_MONEY_ATTEMPT,
  WITHDRAW_MONEY_SUCCESS,
  WITHDRAW_MONEY_ERROR,
  WITHDRAW_MONEY_RESET,
} from '../constants';
import userService from '../../api/services/user';

export const setUserInfo = (type, value) => ({ type: SET_USER_INFO, payload: { type, value } });

export const fetchUserInfo = () => {
  return async dispatch => {
    try {
      const user = await userService.fetchUserInfo();
      dispatch({ type: SET_USERS_ALL_INFO, payload: user });
      dispatch({ type: FETCH_USER_INFO_SUCCESS, payload: user });
    } catch (err) {
      dispatch({ type: FETCH_USER_INFO_ERROR, payload: err });
    }
  };
};

export const postUsersInfo = (firstName, lastName, username, referralCode) => {
  return async dispatch => {
    try {
      dispatch({ type: POST_USERS_INFO_ATTEMPT });

      const usersInfoObj = {
        firstName,
        lastName,
        username,
        referralCode: referralCode === '' ? undefined : referralCode,
      };
      await userService.postUsersInfo(usersInfoObj);
      dispatch({ type: POST_USERS_INFO_SUCCESS });
    } catch (err) {
      dispatch({ type: POST_USERS_INFO_ERROR, payload: err });
    }
  };
};

export const postUsersInfoReset = () => ({ type: POST_USERS_INFO_RESET });

export const uploadUserProfilePhoto = formData => {
  return async dispatch => {
    try {
      dispatch({ type: UPLOAD_PROFILE_PHOTO_ATTEMPT });
      const user = await userService.uploadUserProfilePhoto(formData);
      dispatch({ type: SET_USERS_ALL_INFO, payload: user });
      dispatch({ type: UPLOAD_PROFILE_PHOTO_SUCCESS });
    } catch (err) {
      dispatch({ type: UPLOAD_PROFILE_PHOTO_ERROR, payload: err });
    }
  };
};

export const uploadUserProfilePhotoInfoReset = () => ({ type: UPLOAD_PROFILE_PHOTO_RESET });

export const updateProfile = formData => {
  return async dispatch => {
    try {
      dispatch({ type: UPDATE_PROFILE_ATTEMPT });
      const user = await userService.updateProfile(formData);
      dispatch({ type: SET_USERS_ALL_INFO, payload: user });
      dispatch({ type: UPDATE_PROFILE_SUCCESS });
    } catch (err) {
      dispatch({ type: UPDATE_PROFILE_ERROR, payload: err });
    }
  };
};

export const updateProfileReset = () => ({ type: UPDATE_PROFILE_RESET });

export const withdrawMoney = amount => {
  return async dispatch => {
    try {
      dispatch({ type: WITHDRAW_MONEY_ATTEMPT });
      await userService.withdrawMoney(amount);
      dispatch({ type: WITHDRAW_MONEY_SUCCESS });
    } catch (err) {
      dispatch({ type: WITHDRAW_MONEY_ERROR, payload: err });
    }
  };
};

export const withdrawMoneyReset = () => ({ type: WITHDRAW_MONEY_RESET });

export const resetPassword = data => {
  return async dispatch => {
    try {
      dispatch({ type: RESET_PASSWORD_ATTEMPT });
      await userService.resetPassword(data);
      dispatch({ type: RESET_PASSWORD_SUCCESS });
    } catch (err) {
      dispatch({ type: RESET_PASSWORD_ERROR, payload: err });
    }
  };
};

export const resetPasswordReset = () => ({ type: RESET_PASSWORD_RESET });

export const decreaseUserBalance = amount => ({ type: DECREASE_BALANCE, payload: amount });
