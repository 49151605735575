import { axiosAuthClient } from '..';
import { handleError } from '../../utils/errors';

class AuthService {
  signUpWithEmail = async (email, password) => {
    try {
      const body = { email, password };

      const response = await axiosAuthClient.post('/sign-up', body);
      if (response.status !== 201) {
        throw new Error();
      }
    } catch (err) {
      throw handleError(err, 'Error occured while signing up new user');
    }
  };

  verifyEmail = async (email, code) => {
    try {
      const response = await axiosAuthClient.post('/verify/email', { email, code });
      if (response.status !== 200) {
        throw new Error();
      }
    } catch (err) {
      throw handleError(err, 'Error occured while verifying email');
    }
  };

  setupPhone = async (email, phone) => {
    try {
      const response = await axiosAuthClient.post('/info/phone', { phone, email });
      if (response.status !== 201) {
        throw new Error();
      }
    } catch (err) {
      throw handleError(err, 'Error occured while setting phone');
    }
  };

  verifyPhone = async (phone, code) => {
    try {
      const response = await axiosAuthClient.post('/verify/phone', { phone, code });
      if (response.status !== 200) {
        throw new Error();
      }
      const { accessToken, refreshToken, user } = response.data.data;
      return { accessToken, refreshToken, user };
    } catch (err) {
      throw handleError(err, 'Error occured while verifying phone');
    }
  };

  resendVerificationCodeEmail = async email => {
    try {
      const response = await axiosAuthClient.post('/resend-code/email', { email });
      if (response.status !== 201) {
        throw new Error();
      }
    } catch (err) {
      throw handleError(err, 'Error occured while resending verification code');
    }
  };

  resendVerificatioCodePhone = async phone => {
    try {
      const response = await axiosAuthClient.post('/resend-code/phone', { phone });
      if (response.status !== 201) {
        throw new Error();
      }
    } catch (err) {
      throw handleError(err, 'Error occured while resending verification code');
    }
  };

  loginWithEmail = async (email, password) => {
    try {
      const response = await axiosAuthClient.post('/login', { email, password });
      if (response.status !== 200) {
        throw new Error();
      }
      const { phone } = response.data.data;
      return phone;
    } catch (err) {
      throw handleError(err, 'Error occured while logging in');
    }
  };

  sendResetPasswordEmail = async email => {
    try {
      const response = await axiosAuthClient.post('/resend-code/password', { email });
      if (response.status !== 201) {
        throw new Error();
      }
    } catch (err) {
      throw handleError(err, 'Error occured while sending email');
    }
  };

  resetPassword = async (email, newPassword, code) => {
    try {
      const response = await axiosAuthClient.post('/forgotten-password', { email, newPassword, code });
      if (response.status !== 201) {
        throw new Error();
      }
    } catch (err) {
      throw handleError(err, 'Error occured while sending email');
    }
  };

  logout = async refreshToken => {
    try {
      const response = await axiosAuthClient.post('/logout', { refreshToken });
      if (response.status !== 201) {
        throw new Error();
      }
    } catch (err) {
      throw handleError(err, 'Error occured while sending email');
    }
  };
}

export default new AuthService();
