import planetVertical from '../assets/images/planet-vertical.png';
import planetHorizontal from '../assets/images/planet-horizontal.png';
import loginPlanet from '../assets/images/login-planet.png';
import earth from '../assets/images/earth.png';
import mac from '../assets/images/mac.png';
import earthThumbnail1 from '../assets/images/earth-thumbnail-1.png';
import earthThumbnail2 from '../assets/images/earth-thumbnail-2.png';
import earthThumbnail3 from '../assets/images/earth-thumbnail-3.png';
import earthThumbnail4 from '../assets/images/earth-thumbnail-4.png';
import coverPhotoText from '../assets/images/cover-photo-text.png';
import earth1 from '../assets/images/earth1.png';

const images = {
  planetVertical,
  planetHorizontal,
  loginPlanet,
  earth,
  mac,
  earthThumbnail1,
  earthThumbnail2,
  earthThumbnail3,
  earthThumbnail4,
  coverPhotoText,
  earth1,
};

export default images;
