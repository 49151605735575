import React, { useEffect, useState } from 'react';
import { Images, Icons } from '../../themes';
import { Button } from '../../components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import landService from '../../api/services/land';

const HomePage = () => {
  const loggedIn = useSelector(state => state.auth.loggedIn);

  const [priceHistory, setPriceHistory] = useState([]);

  useEffect(() => {
    async function fetchUsers() {
      try {
        const results = await landService.getPriceHistory();

        setPriceHistory(results);
      } catch (error) {
        console.log(error.message);
      }
    }

    fetchUsers();
  }, []);

  return (
    <div className="home__screen">
      <div className="home__screen__header">
        {priceHistory.length > 0 &&
          priceHistory.map((p, ind) => {
            return (
              <div key={ind} className="home__screen__header__card">
                <p className="home__screen__header__card-title">{p.districtName}</p>
                <div className="home__screen__header__card__price__container">
                  <p className="home__screen__header__card-price">E${p.price}</p>
                  <p className="home__screen__header__card-increase">+{p.increase}%</p>
                </div>
              </div>
            );
          })}
      </div>
      <div className="home__screen__first__container">
        <h1 className="home__screen__first__container-title">Pioneer A Virtual Economy.</h1>
        <Link to="/earth?page=1" className="home__screen__first__container-button">
          <Button title="Check it Out" />
        </Link>
        <img className="home__screen__first__container-image" alt="homeimage" src={Images.planetHorizontal} />
      </div>
      <div className="home__screen__second__container">
        <div className="home__screen__second__container__content">
          <img src={Icons.eu} className="home__screen__second__container__content-logo" alt="eu-logo" />
          <p className="home__screen__second__container__content-title">
            Expansion Unlimited is a virtual landscape; a parallel world where users can indulge themselves in the
            buying and selling of plots of land in cyberspace and grow their asset portfolio while immersing themselves
            in our virtual community.
          </p>
          <p className="home__screen__second__container__content-text">
            We aim to create a world in which user assets, specifically tiles, have the potential to increase in
            perceived value over time contingent upon location, demand, earning potential, and time invested into users’
            portfolios. Built using MapBox technology, our platform will display a virtual 1:1 scale model of the earth
            divided into 20x20 meter plots of land. This enhanced rendition of the earth will serve as the foundation of
            Expansion Unlimited. Tile owners will be able to market their assets and trade with others in our
            marketplace which is solely controlled by users. Competition for tiles is unrestricted, meaning users can
            set their own prices and engage in dynamic relationships with other tile owners.
          </p>
        </div>
        <div className="home__screen__second__container__features__content">
          <img
            alt="earth"
            src={Images.earth}
            className="home__screen__second__container__features__content-earth-image"
          />
          <div>
            <p className="home__screen__second__container__features__content-title">
              Welcome to the new age of virtual assets. Engage with countless others to help build the virtual economy
              of Expansion Unlimited.
            </p>
            <div className="home__screen__second__container__features__content__item">
              <img
                className="home__screen__second__container__features__content__item-icon"
                src={Icons.twitter}
                alt="correct-icon"
              />
              <img
                className="home__screen__second__container__features__content__item-icon"
                src={Icons.instagram}
                alt="correct-icon"
              />
              <img
                className="home__screen__second__container__features__content__item-icon"
                src={Icons.facebook}
                alt="correct-icon"
              />
              <img
                className="home__screen__second__container__features__content__item-icon"
                src={Icons.discord}
                alt="correct-icon"
              />
              <img
                className="home__screen__second__container__features__content__item-icon"
                src={Icons.reddit}
                alt="correct-icon"
              />
            </div>
            <p className="home__screen__second__container__features__content__item-text">START NOW WITH</p>
            <p className="home__screen__second__container__features__content__item-title">EXPANSION UNLIMITED</p>
            <Link to="/earth?page=1">
              <Button title="Buy Land" style={{ width: '38.6rem' }} />
            </Link>
          </div>
        </div>
      </div>
      <div
        className="home__screen__video_container"
        onClick={() => window.open('https://www.youtube.com/watch?v=FYOH_54XEJY')}>
        <img className="home__screen__video_container-image" alt="homeimage" src={Images.planetHorizontal} />
        <div className="home__screen__video_container__icon__container">
          <img className="home__screen__video_container__icon__container-icon" alt="playButton" src={Icons.play} />
          <p className="home__screen__video_container__icon__container-text">Watch our video</p>
        </div>
      </div>
      <div className="home__screen__third__container" />
      <img className="home__screen__third__container-mac__image" src={Images.mac} alt="mac" />
      <div className="home__screen__third__container__content">
        <p className="home__screen__third__container__content-title">Refferal Program</p>
        <p className="home__screen__third__container__content-text">
          Users can acquire a unique referral code to share with others. Those who use a referral code can benefit from
          a 5% discount and 5% of their purchase amount will be rewarded to the owner of the code. Join now to receive
          your own referral code.
        </p>
        <Link to="/auth/sign-up">
          <Button className="home__screen__third__container__content-button" title="Join Now" />
        </Link>
      </div>
      <div className="home__screen__fourth__container">
        <div className="home__screen__fourth__container__content">
          <h1 className="home__screen__fourth__container__content-title">Stage One</h1>
          <p className="home__screen__fourth__container__content-feature-text">
            Stage one will be the inaugural launch of the Expansion Unlimited platform. This launch will be the
            commencement of users’ ability to purchase land tiles. It will also include the release of the marketplace
            in which users can search, purchase, or market any tiles that are circulating throughout the marketplace.
          </p>
          {/* <p className="home__screen__fourth__container__content-learn__more">Learn More</p> */}
        </div>
        <div className="home__screen__fourth__container__images">
          <img className="home__screen__fourth__container__images-earth" src={Images.earthThumbnail1} alt="earth-1" />
          <img src={Images.earthThumbnail2} alt="earth-2" />
          <img className="home__screen__fourth__container__images-earth" src={Images.earthThumbnail3} alt="earth-3" />
          <img src={Images.earthThumbnail4} alt="earth-4" />
        </div>
      </div>
      <div className="home__screen__fifth__container">
        <div className="home__screen__fifth__container__image__container">
          <img className="home__screen__fifth__container__image__container-image" src={Images.earth1} alt="earth1" />
          <div className="home__screen__fifth__container__text__container">
            <p className="home__screen__fifth__container__text__container-title">Our Future</p>
            <p className="home__screen__fifth__container__text__container-text">
              The future of Expansion Unlimited is promising.
            </p>
            {/* <Link to="/our-future" className="home__screen__fifth__container__text__container-link">
              Click here to learn more about the many new features and updates coming soon.
            </Link> */}
          </div>
        </div>
      </div>
      <div className="home__screen__footer">
        <div className="home__screen__footer__content">
          <h2 className="home__screen__footer__content-title">Join Expansion Unlimited Now!</h2>
          {!loggedIn ? (
            <Link to="/auth/sign-up">
              <Button title="Sign Up" className="home__screen__footer__content-sign__up__btn" />
            </Link>
          ) : (
            <div className="home__screen__footer__content-spacing"></div>
          )}
          <div className="home__screen__footer__content__pages__container">
            <Link className="home__screen__footer__content__pages__container-text" to="/earth?page=1">
              <p>Buy Land</p>
            </Link>
            <Link className="home__screen__footer__content__pages__container-text" to="/marketplace?page=1">
              <p>Marketplace</p>
            </Link>
            <Link className="home__screen__footer__content__pages__container-text" to="/leaderboard">
              <p>Leadboard</p>
            </Link>
            {/* <Link className="home__screen__footer__content__pages__container-text" to="/our-future">
              <p>Our future</p>
            </Link> */}
          </div>
          <div className="home__screen__second__container__features__content__item">
            <img
              className="home__screen__second__container__features__content__item-icon"
              src={Icons.twitter}
              alt="correct-icon"
            />
            <img
              className="home__screen__second__container__features__content__item-icon"
              src={Icons.instagram}
              alt="correct-icon"
            />
            <img
              className="home__screen__second__container__features__content__item-icon"
              src={Icons.facebook}
              alt="correct-icon"
            />
            <img
              className="home__screen__second__container__features__content__item-icon"
              src={Icons.discord}
              alt="correct-icon"
            />
            <img
              className="home__screen__second__container__features__content__item-icon"
              src={Icons.reddit}
              alt="correct-icon"
            />
          </div>
          <p className="home__screen__footer__content-info__text">Expansion Unlimited © 2021. All rights reserved</p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
