import React, { useEffect, useState } from 'react';
import { Button, Pagination, SelectInput } from '../../components';
import landService from '../../api/services/land';
import { useHistory, useLocation } from 'react-router';

const MarketplaceScreen = () => {
  const [lands, setLands] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [perPage, setPerPage] = useState(1);
  let page = +useLocation().search.split('=')[1];
  const history = useHistory();
  const [sortBySearch, setSortBySearch] = useState('');
  const [stateSearch, setStateSearch] = useState('');
  const [stateSearchOptions, setStateSearchOptions] = useState([]);

  const setPage = async page => {
    setCurrentPage(page);
    history.push({
      pathname: '/marketplace',
      search: `?page=${page}`,
    });

    try {
      const landSort = handleSort();
      const response1 = await landService.getLandsOnSale(Number(page), null, stateSearch, null, landSort);
      setPerPage(response1.perPage);
      setTotalCount(response1.total);
      setLands(response1.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    async function fetchCountries() {
      try {
        const countries = await landService.getCountries();
        const states = [];
        countries.forEach(country => {
          country.states.forEach(state => {
            states.push(state.name);
          });
        });
        setStateSearchOptions(states);
      } catch (error) {
        console.log(error.message);
      }
    }

    fetchCountries();
  }, []);

  const handleSort = () => {
    const landSort = {};
    if (sortBySearch === 'Land Value Low') {
      landSort.landPrice = 'ASC';
    } else if (sortBySearch === 'Land Value High') {
      landSort.landPrice = 'DESC';
    } else if (sortBySearch === 'Tile Value Low') {
      landSort.tilePrice = 'ASC';
    } else if (sortBySearch === 'Tile Value High') {
      landSort.tilePrice = 'DESC';
    }
    return landSort;
  };

  useEffect(() => {
    async function fetchApi() {
      setCurrentPage(page);
      try {
        const landSort = handleSort();
        const response1 = await landService.getLandsOnSale(Number(page), null, stateSearch, null, landSort);
        setPerPage(response1.perPage);
        setTotalCount(response1.total);
        setLands(response1.data);
      } catch (error) {
        console.log(error.message);
      }
    }
    if (page > 0) fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, stateSearch, sortBySearch]);

  return (
    <div className="marketplace__screen">
      <div className="marketplace__screen__main__container">
        <p className="marketplace__screen-title">Marketplace</p>
        <div className="marketplace__screen__select__container">
          <SelectInput
            id="sortBy"
            label="Sort by"
            currentValue={sortBySearch}
            setOption={setSortBySearch}
            options={['Tile Value Low', 'Tile Value High', 'Land Value Low', 'Land Value High']}
          />
          <SelectInput
            id="state"
            label="State"
            currentValue={stateSearch}
            setOption={setStateSearch}
            options={stateSearchOptions}
          />
        </div>
        <div className="marketplace__screen__table">
          <div className="marketplace__screen__header">
            <div className="marketplace__screen__col-1">
              <p className="marketplace__screen__col-title">Tiles</p>
            </div>
            <div className="marketplace__screen__col-2">
              <p className="marketplace__screen__col-title">Country</p>
            </div>
            <div className="marketplace__screen__col-3">
              <p className="marketplace__screen__col-title">Price</p>
            </div>
            <div className="marketplace__screen__col-4">
              <p className="marketplace__screen__col-title">Market Value</p>
            </div>
            <div className="marketplace__screen__col-5">
              <p className="marketplace__screen__col-title">Name</p>
            </div>
            <div className="marketplace__screen__col-6"></div>
          </div>
          {lands.map(item => (
            <MarketplaceItem key={item.id} item={item} />
          ))}
          <div className="marketplace__screen__footer" />
        </div>
        <div className="marketplace__screen__pagination__container">
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={perPage}
            onPageChange={page => setPage(page)}
          />
        </div>
      </div>
    </div>
  );
};

const MarketplaceItem = ({ item }) => {
  const history = useHistory();
  return (
    <div className="marketplace__screen__row">
      <div className="marketplace__screen__col-1">
        <p className="marketplace__screen__col-value">{item.tiles}</p>
      </div>
      <div className="marketplace__screen__col-2">
        <p className="marketplace__screen__col-value">{item.country}</p>
      </div>
      <div className="marketplace__screen__col-3">
        <p className="marketplace__screen__col-price">E${item.sellingPrice.toFixed(2)}</p>
        <p className="marketplace__screen__col-value">{(item.sellingPrice / item.tiles).toFixed(2)} PER TILE</p>
      </div>
      <div className="marketplace__screen__col-4">
        <p className="marketplace__screen__col-market-value">E${item.marketPrice.toFixed(2)}</p>
        <p className="marketplace__screen__col-value">{(item.marketPrice / item.tiles).toFixed(2)} PER TILE</p>
      </div>
      <div className="marketplace__screen__col-5">
        <p className="marketplace__screen__col-value">{item.title}</p>
      </div>
      <div className="marketplace__screen__col-6">
        <Button
          className="marketplace__screen__col-6-btn"
          onClick={() =>
            history.push({
              pathname: '/earth',
              search: `page=1&x=${item.coordinates.x}&y=${item.coordinates.y}`,
            })
          }
          title="View"
        />
        <Button
          className="marketplace__screen__col-6-btn marketplace__screen__col-6-black-btn"
          onClick={() => history.push(`land/${item.id}?page=1`)}
          title="Buy"
        />
      </div>
    </div>
  );
};

export default MarketplaceScreen;
