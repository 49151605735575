import React, { useRef, useState } from 'react';

const InputCode = ({ value, setValue, length }) => {
  const input = useRef();

  //   const [code, setCode] = useState('');
  const [focused, setFocused] = useState(false);

  const codeArray = new Array(length).fill(null);

  const handleInput = event => {
    const text = event.target.value;
    setValue(text);
  };

  return (
    <div
      className="container"
      onClick={() => {
        input.current.focus();
        setFocused(true);
      }}>
      <input
        value={value}
        onChange={handleInput}
        onBlur={() => setFocused(false)}
        ref={input}
        type="text"
        maxLength={length}
      />
      {codeArray.map((_, index) => {
        return (
          <div key={index} className={`${value.length === index && focused ? 'active' : ''}`}>
            {value[index]}
          </div>
        );
      })}
    </div>
  );
};

export default InputCode;
