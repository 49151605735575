import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

function PrivateRoute({ children, loggedIn, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return loggedIn ? children : <Redirect to={{ pathname: '/auth/sign-in', state: { from: location } }} />;
      }}
    />
  );
}

const mapStateToProps = state => ({
  loggedIn: state.auth.loggedIn,
});

export default connect(mapStateToProps, null)(PrivateRoute);
