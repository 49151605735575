import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Input } from '..';
import { postUsersInfo, postUsersInfoReset, setUserInfo } from '../../store/actions/user';
import { userReducerType } from '../../utils/constants';

const BasicInfoForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUserName] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [error, setError] = useState('');

  const postUsersInfoAction = useSelector(state => state.userInfo.postUsersInfo);
  const loggedUsername = useSelector(state => state.userInfo.username);

  useEffect(() => {
    if (loggedUsername) {
      history.push('/earth?page=1');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onContinueClick = () => {
    if (!firstName) {
      setError('First name field must be filled.');
      return;
    }
    if (!lastName) {
      setError('Last name field must be filled.');
      return;
    }
    if (!username) {
      setError(' Username field must be filled.');
      return;
    }

    setError('');
    dispatch(postUsersInfo(firstName, lastName, username, referralCode));
  };

  useEffect(() => {
    if (postUsersInfoAction.success) {
      dispatch(postUsersInfoReset());
      history.push('/auth/profile-photo');
      dispatch(setUserInfo(userReducerType.username, username));
      dispatch(setUserInfo(userReducerType.firstName, firstName));
      dispatch(setUserInfo(userReducerType.lastName, lastName));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postUsersInfoAction, history, dispatch]);

  useEffect(() => {
    if (postUsersInfoAction.error) {
      setError(postUsersInfoAction.error.message);
      dispatch(postUsersInfoReset());
    }
  }, [postUsersInfoAction, dispatch]);
  return (
    <>
      <p className="auth__screen__container-text">Tell us about yourself</p>
      <div className="auth__screen__container__name_container">
        <Input
          type="text"
          className="auth__screen__container__name_container-input__left"
          onChange={setFirstName}
          placeholder="First Name"
        />
        <Input
          type="text"
          className="auth__screen__container__name_container-input"
          onChange={setLastName}
          placeholder="Last Name"
        />
      </div>
      <Input type="text" className="auth__screen__container-input" onChange={setUserName} placeholder="Username" />
      <Input
        type="text"
        className="auth__screen__container-input"
        onChange={setReferralCode}
        placeholder="Referral Code (optional)"
      />
      <div style={{ color: 'white' }}>{error}</div>
      <Button onClick={onContinueClick} title="Continue" className="auth__screen__container-button" />
    </>
  );
};

export default BasicInfoForm;
