import {
  FETCH_USER_INFO_ERROR,
  FETCH_USER_INFO_SUCCESS,
  LOGIN_ATTEMPT,
  LOGIN_ERROR,
  LOGIN_RESET,
  LOGIN_SUCCESS,
  LOGOUT,
  RESEND_EMAIL_VERIFICATION_CODE_ATTEMPT,
  RESEND_EMAIL_VERIFICATION_CODE_ERROR,
  RESEND_EMAIL_VERIFICATION_CODE_RESET,
  RESEND_EMAIL_VERIFICATION_CODE_SUCCESS,
  RESEND_PHONE_VERIFICATION_CODE_ATTEMPT,
  RESEND_PHONE_VERIFICATION_CODE_ERROR,
  RESEND_PHONE_VERIFICATION_CODE_RESET,
  RESEND_PHONE_VERIFICATION_CODE_SUCCESS,
  RESET_FORGOTTEN_PASSWORD_ATTEMPT,
  RESET_FORGOTTEN_PASSWORD_ERROR,
  RESET_FORGOTTEN_PASSWORD_RESET,
  RESET_FORGOTTEN_PASSWORD_SUCCESS,
  SEND_RESET_PASSWORD_EMAIL_ATTEMPT,
  SEND_RESET_PASSWORD_EMAIL_ERROR,
  SEND_RESET_PASSWORD_EMAIL_RESET,
  SEND_RESET_PASSWORD_EMAIL_SUCCESS,
  SETUP_PHONE_ATTEMPT,
  SETUP_PHONE_ERROR,
  SETUP_PHONE_RESET,
  SETUP_PHONE_SUCCESS,
  SET_INPUT_EMAIL,
  SET_INPUT_PHONE,
  SIGN_UP_ATTEMPT,
  SIGN_UP_ERROR,
  SIGN_UP_RESET,
  SIGN_UP_SUCCESS,
  VERIFY_EMAIL_ATTEMPT,
  VERIFY_EMAIL_ERROR,
  VERIFY_EMAIL_RESET,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_PHONE_ATTEMPT,
  VERIFY_PHONE_ERROR,
  VERIFY_PHONE_RESET,
  VERIFY_PHONE_SUCCESS,
} from '../constants';

const INIT_STATE = {
  loggedIn: null,
  inputEmail: '',
  inputPhone: '',
  login: {
    attempt: false,
    success: false,
    error: null,
  },
  signUp: {
    attempt: false,
    success: false,
    error: null,
  },
  verifyEmail: {
    attempt: false,
    success: false,
    error: null,
  },
  verifyPhone: {
    attempt: false,
    success: false,
    error: null,
  },
  resendEmailCode: {
    attempt: false,
    success: false,
    error: null,
  },
  resendPhoneCode: {
    attempt: false,
    success: false,
    error: null,
  },
  setupPhone: {
    attempt: false,
    success: false,
    error: null,
  },
  sendResetPasswordMail: {
    attempt: false,
    success: false,
    error: null,
  },
  resetForgottenPassword: {
    attempt: false,
    success: false,
    error: null,
  },
};

const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGN_UP_ATTEMPT:
      return {
        ...state,
        signUp: { attempt: true, success: false, error: null },
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        signUp: { attempt: false, success: true, error: null },
      };
    case SIGN_UP_ERROR:
      return {
        ...state,
        signUp: { attempt: false, success: false, error: action.payload },
      };
    case SIGN_UP_RESET:
      return {
        ...state,
        signUp: { attempt: false, success: false, error: null },
      };
    case VERIFY_EMAIL_ATTEMPT:
      return {
        ...state,
        verifyEmail: { attempt: true, success: false, error: null },
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        verifyEmail: { attempt: false, success: true, error: null },
      };
    case VERIFY_EMAIL_ERROR:
      return {
        ...state,
        verifyEmail: { attempt: false, success: false, error: action.payload },
      };
    case VERIFY_EMAIL_RESET:
      return {
        ...state,
        verifyEmail: { attempt: false, success: false, error: null },
      };

    case VERIFY_PHONE_ATTEMPT:
      return {
        ...state,
        verifyPhone: { attempt: true, success: false, error: null },
      };
    case VERIFY_PHONE_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        inputPhone: '',
        verifyPhone: { attempt: false, success: true, error: null },
      };
    case LOGOUT:
      return {
        ...INIT_STATE,
        loggedIn: false,
      };
    case VERIFY_PHONE_ERROR:
      return {
        ...state,
        verifyPhone: { attempt: false, success: false, error: action.payload },
      };
    case VERIFY_PHONE_RESET:
      return {
        ...state,
        verifyPhone: { attempt: false, success: false, error: null },
      };
    case RESEND_EMAIL_VERIFICATION_CODE_ATTEMPT:
      return {
        ...state,
        resendEmailCode: { attempt: true, success: false, error: null },
      };
    case RESEND_EMAIL_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        resendEmailCode: { attempt: false, success: true, error: null },
      };
    case RESEND_EMAIL_VERIFICATION_CODE_ERROR:
      return {
        ...state,
        resendEmailCode: { attempt: false, success: false, error: action.payload },
      };
    case RESEND_EMAIL_VERIFICATION_CODE_RESET:
      return {
        ...state,
        resendEmailCode: { attempt: false, success: false, error: null },
      };
    case RESEND_PHONE_VERIFICATION_CODE_ATTEMPT:
      return {
        ...state,
        resendPhoneCode: { attempt: true, success: false, error: null },
      };
    case RESEND_PHONE_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        resendPhoneCode: { attempt: false, success: true, error: null },
      };
    case RESEND_PHONE_VERIFICATION_CODE_ERROR:
      return {
        ...state,
        resendPhoneCode: { attempt: false, success: false, error: action.payload },
      };
    case RESEND_PHONE_VERIFICATION_CODE_RESET:
      return {
        ...state,
        resendPhoneCode: { attempt: false, success: false, error: null },
      };

    case SETUP_PHONE_ATTEMPT:
      return {
        ...state,
        setupPhone: { attempt: true, success: false, error: null },
      };
    case SETUP_PHONE_SUCCESS:
      return {
        ...state,
        setupPhone: { attempt: false, success: true, error: null },
      };
    case SETUP_PHONE_ERROR:
      return {
        ...state,
        setupPhone: { attempt: false, success: false, error: action.payload },
      };
    case SETUP_PHONE_RESET:
      return {
        ...state,
        setupPhone: { attempt: false, success: false, error: null },
      };
    case FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        loggedIn: true,
      };
    case FETCH_USER_INFO_ERROR:
      return {
        ...state,
        loggedIn: false,
      };
    case SET_INPUT_EMAIL:
      return {
        ...state,
        inputEmail: action.payload,
      };
    case SET_INPUT_PHONE:
      return {
        ...state,
        inputPhone: action.payload,
      };
    case LOGIN_ATTEMPT:
      return {
        ...state,
        login: { attempt: true, success: false, error: null },
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        inputEmail: '',
        login: { attempt: false, success: true, error: null },
      };
    case LOGIN_ERROR:
      return {
        ...state,
        login: { attempt: false, success: false, error: action.payload },
      };
    case LOGIN_RESET:
      return {
        ...state,
        login: { attempt: false, success: false, error: null },
      };
    case SEND_RESET_PASSWORD_EMAIL_ATTEMPT:
      return {
        ...state,
        sendResetPasswordMail: { attempt: true, success: false, error: null },
      };
    case SEND_RESET_PASSWORD_EMAIL_SUCCESS:
      return {
        ...state,
        sendResetPasswordMail: { attempt: false, success: true, error: null },
      };
    case SEND_RESET_PASSWORD_EMAIL_ERROR:
      return {
        ...state,
        sendResetPasswordMail: { attempt: false, success: false, error: action.payload },
      };
    case SEND_RESET_PASSWORD_EMAIL_RESET:
      return {
        ...state,
        sendResetPasswordMail: { attempt: false, success: false, error: null },
      };
    case RESET_FORGOTTEN_PASSWORD_ATTEMPT:
      return {
        ...state,
        resetForgottenPassword: { attempt: true, success: false, error: null },
      };
    case RESET_FORGOTTEN_PASSWORD_SUCCESS:
      return {
        ...state,
        inputEmail: '',
        resetForgottenPassword: { attempt: false, success: true, error: null },
      };
    case RESET_FORGOTTEN_PASSWORD_ERROR:
      return {
        ...state,
        resetForgottenPassword: { attempt: false, success: false, error: action.payload },
      };
    case RESET_FORGOTTEN_PASSWORD_RESET:
      return {
        ...state,
        resetForgottenPassword: { attempt: false, success: false, error: null },
      };
    default:
      return state;
  }
};

export default authReducer;
