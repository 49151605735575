import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useLocation, Switch } from 'react-router-dom';
import Header from '../Header/Header';
import {
  HomeScreen,
  AuthScreen,
  MapBox,
  SplashScreen,
  ErrorScreen,
  ProfileScreen,
  MyPortfolioScreen,
  LandScreen,
  AddCreditScreen,
  MarketplaceScreen,
  LeaderboardScreen,
} from '../../screens';
import { fetchUserInfo } from '../../store/actions/user';
import { toast, ToastContainer } from 'react-toastify';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [minimumDurationPassed, setMinimumDurationPassed] = useState(false);
  const loggedIn = useSelector(state => state.auth.loggedIn);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  useEffect(() => {
    const timer = setTimeout(() => setMinimumDurationPassed(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Fragment>
      {loggedIn !== null && minimumDurationPassed ? (
        <div className="app" style={{ height: '100%' }}>
          <ToastContainer newestOnTop={true} autoClose={3500} position={toast.POSITION.TOP_CENTER} />
          {!location.pathname.startsWith('/auth') ? <Header /> : null}
          <Switch>
            <Route path="/" exact component={HomeScreen} />
            <Route path="/auth">
              <AuthScreen />
            </Route>
            <Route path="/earth">
              <MapBox />
            </Route>
            <Route path="/profile">
              <ProfileScreen />
            </Route>
            <PrivateRoute path="/my-portfolio">
              <MyPortfolioScreen />
            </PrivateRoute>
            <Route path="/land/:id">
              <LandScreen />
            </Route>
            <PrivateRoute path="/add-credit">
              <AddCreditScreen />
            </PrivateRoute>
            <Route path="/marketplace">
              <MarketplaceScreen />
            </Route>
            <Route path="/leaderboard">
              <LeaderboardScreen />
            </Route>
            <Route>
              <ErrorScreen />
            </Route>
          </Switch>
        </div>
      ) : (
        <SplashScreen />
      )}
    </Fragment>
  );
}

export default App;
