import React, { useEffect, useRef, useState } from 'react';
import { Icons } from '../../themes';

const SelectInput = ({ id, label, options, setOption, currentValue }) => {
  const [display, setDisplay] = useState(false);
  const wrapperRef = useRef(null);
  const inputRet = useRef();
  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  });
  const [value, setValue] = useState('');

  useEffect(() => {
    if (currentValue === '') {
      setValue('');
    }
  }, [currentValue]);

  const handleClickOutside = event => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      setDisplay(false);
    }
  };

  const updateState = value => {
    setValue(value);
    setOption(value);
    setDisplay(false);
  };

  const onInputClick = () => {
    if (!display) {
      setDisplay(!display);
    }
  };

  useEffect(() => {
    if (!value.trim().length) {
      setOption('');
    }
  }, [value, setOption]);

  return (
    <div ref={wrapperRef} className={'select__container ' + (display ? 'select__container-clicked' : '')}>
      <label className="select__container-label" htmlFor={id}>
        {label}:
      </label>

      <input
        id={id}
        onClick={onInputClick}
        value={value}
        onChange={event => setValue(event.target.value)}
        ref={inputRet}
        autoComplete="off"
      />
      {display && (
        <div className="select__container__options">
          {options
            .filter(name => name.toLowerCase().includes(value.toLowerCase()))
            .map((value, i) => {
              return (
                <div
                  onClick={() => updateState(value)}
                  className="select__container__options-option"
                  key={i}
                  tabIndex="0">
                  <span>{value}</span>
                </div>
              );
            })}
        </div>
      )}
      {display ? (
        <img onClick={() => setDisplay(!display)} src={Icons.DropUp} alt="land_photo" />
      ) : (
        <img onClick={() => setDisplay(!display)} src={Icons.DropDown} alt="land_photo" />
      )}
    </div>
  );
};

export default SelectInput;
