import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '../Button/Button';
import { Icons } from '../../themes';
import { useHistory, useLocation } from 'react-router-dom';

const Header = () => {
  const loggedIn = useSelector(state => state.auth.loggedIn);
  const user = useSelector(state => state.userInfo);
  const history = useHistory();
  const location = useLocation();

  return (
    <nav className={loggedIn ? 'loggedInHeader' : 'header'}>
      <div className="headerContainer">
        {!loggedIn ? (
          <Link to="/" className="appTitle">
            <img src={Icons.eu} alt="eu-logo" />
          </Link>
        ) : null}
        <div className="navigator">
          {loggedIn ? (
            <Link to="/">
              <img className="headerLogo" src={Icons.eu} alt="eu-logo" />
            </Link>
          ) : null}
          <Link to="/earth?page=1" className={`link ${location.pathname.includes('earth') && 'activeLink'} `}>
            Buy Land
          </Link>
          <Link
            to="/marketplace?page=1"
            className={`link ${location.pathname.includes('marketplace') && 'activeLink'} `}>
            Marketplace
          </Link>
          <Link to="/leaderboard" className={`link ${location.pathname.includes('leaderboard') && 'activeLink'} `}>
            Leaderboard
          </Link>
          {loggedIn && (
            <>
              <Link
                to="/my-portfolio?page=1"
                className={`link ${location.pathname.includes('my-portfolio') && 'activeLink'} `}>
                My Portfolio
              </Link>
              <Link to="/profile" className={`link ${location.pathname.includes('profile') && 'activeLink'} `}>
                Settings
              </Link>
            </>
          )}
        </div>
        {loggedIn ? (
          <div className="user__details__container">
            <div className="user__details__container__balance__container">
              <div className="user__details__container__balance__container__avatar__container">
                <span className="user__details__container__balance__container__avatar__container-text">$</span>
              </div>
              <div>
                <p className="user__details__container__balance__container-balance__text">
                  ${parseFloat(user.balance).toFixed(2)}
                </p>
                <Link to="/add-credit" className="user__details__container__balance__container-add__credit">
                  <p>Add credit</p>
                </Link>
              </div>
            </div>
            <div
              onClick={() => history.push('/my-portfolio?page=1')}
              className="user__details__container__user__info__container">
              <div className="user__details__container__user__info__container__avatar__container">
                {!user.profilePhoto ? (
                  <span className="user__details__container__user__info__container__avatar__container-text">
                    {user.firstName[0]}
                    {user.lastName[0]}
                  </span>
                ) : (
                  <img
                    className="user__details__container__user__info__container__avatar__container-profile__photo"
                    src={user.profilePhoto}
                    alt="user_profile_photo"
                  />
                )}
              </div>
              <p>
                {user.firstName} {user.lastName}
              </p>
            </div>
          </div>
        ) : (
          <div className="authContainer">
            <Link to="/auth/sign-up" className="registerLink">
              Register
            </Link>
            <Link to="/auth/sign-in">
              <Button title="Login" className="loginBtn" />
            </Link>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
