import axiosApiClient from '..';
import { handleError } from '../../utils/errors';

const BASE_URL = '/tiles';

class TileService {
  fetchTilesStatus = async visibleTiles => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/status`, { tiles: visibleTiles });
      if (response.status !== 200) {
        throw new Error();
      }
      const { tiles } = response.data.data;
      return tiles;
    } catch (err) {
      throw handleError(err, 'Error occured while fetching tiles');
    }
  };

  fetchTilePrice = async data => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/price`, data);
      if (response.status !== 200) {
        throw new Error();
      }
      const { priceInfo } = response.data.data;
      return priceInfo;
    } catch (err) {
      throw handleError(err, 'Error occured while fetching tiles price');
    }
  };
}

export default new TileService();
