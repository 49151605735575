import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Icons } from '../../themes';
const Modal = forwardRef(({ children, title, onClose, maxWidth, marginTop }, ref) => {
  const [visible, setVisibility] = useState(false);

  useImperativeHandle(ref, () => ({
    show() {
      setVisibility(true);
    },
    hide() {
      setVisibility(false);
    },
  }));

  if (!visible) return null;

  return (
    <div className="modal">
      <div className="modal__content" style={{ maxWidth, marginTop }}>
        <div className="modal__header">
          <p></p>
          <p className="modal__header-title">{title}</p>
          <div className="modal__header__icon-box" onClick={onClose}>
            <img src={Icons.close} alt="close-icon" />
          </div>
        </div>
        <div className="modal__content__inner">{children}</div>
      </div>
    </div>
  );
});

Modal.defaultProps = {
  title: 'Title',
  maxWidth: '40%',
  marginTop: 0,
  onClose: () => console.log(),
};

export default Modal;
