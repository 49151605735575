import grid from '../assets/icons/grid-view.svg';
import euLogo from '../assets/icons/EU.svg';
import correct from '../assets/icons/correct.svg';
import leftArrow from '../assets/icons/left-arrow.svg';
import rightArrow from '../assets/icons/right-arrow.svg';
import close from '../assets/icons/close.svg';
import boughtValue from '../assets/icons/bought-value.svg';
import priceValue from '../assets/icons/price-value.svg';
import marketValue from '../assets/icons/market-value.svg';
import DropDown from '../assets/icons/drop-down.svg';
import DropUp from '../assets/icons/drop-up.svg';
import eu from '../assets/icons/eu-logo.svg';
import discord from '../assets/icons/discord.svg';
import reddit from '../assets/icons/reddit.svg';
import twitter from '../assets/icons/twitter.svg';
import instagram from '../assets/icons/instagram.svg';
import facebook from '../assets/icons/facebook.svg';
import play from '../assets/icons/play.svg';

const icons = {
  grid,
  euLogo,
  correct,
  leftArrow,
  rightArrow,
  close,
  boughtValue,
  priceValue,
  marketValue,
  DropDown,
  DropUp,
  eu,
  discord,
  reddit,
  twitter,
  instagram,
  facebook,
  play,
};

export default icons;
