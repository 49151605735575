import axiosApiClient from '..';
import { handleError } from '../../utils/errors';

const BASE_URL = '/lands';

class LandService {
  buyLand = async data => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/buy`, data);
      if (response.status !== 200) {
        throw new Error();
      }
      const { land } = response.data.data;
      return land;
    } catch (err) {
      throw handleError(err, 'Error occured while buying land');
    }
  };

  sellLand = async data => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/sell`, data);
      if (response.status !== 200) {
        throw new Error();
      }
    } catch (err) {
      throw handleError(err, 'Error occured while selling land');
    }
  };

  getUsersLands = async page => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}?page=${page}`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { data, total, perPage } = response.data;
      return { data, total, perPage };
    } catch (err) {
      throw handleError(err, 'Error occured while fetching user lands');
    }
  };

  getLandsOnSale = async (page, country, state, district, landSort) => {
    try {
      const params = {
        page: page,
      };
      if (country) {
        params.country = country;
      }
      if (state) {
        params.state = state;
      }
      if (district) {
        params.district = district;
      }
      if (landSort?.landPrice) {
        params.landPrice = landSort.landPrice;
      }
      if (landSort?.tilePrice) {
        params.tilePrice = landSort.tilePrice;
      }

      const response = await axiosApiClient.get(`${BASE_URL}-on-sale`, { params: params });
      if (response.status !== 200) {
        throw new Error();
      }
      const { data, total, perPage } = response.data;
      return { data, total, perPage };
    } catch (err) {
      throw handleError(err, 'Error occured while fetching lands on sale');
    }
  };

  getEventsByLand = async (landId, page) => {
    try {
      const response = await axiosApiClient.get(`events/${landId}?page=${page}`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { data, total, perPage } = response.data;
      return { data, total, perPage };
    } catch (err) {
      throw handleError(err, 'Error occured while fetching user lands');
    }
  };

  getLandById = async id => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}/${id}`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { data } = response.data;
      return data;
    } catch (err) {
      throw handleError(err, 'Error occured while fetching land');
    }
  };

  buyLandFromUser = async (id, landName) => {
    try {
      const response = await axiosApiClient.put(`${BASE_URL}-buy/${id}`, { landName });
      if (response.status !== 200) {
        throw new Error();
      }
      const { message } = response.data;
      return message;
    } catch (err) {
      throw handleError(err, 'Error occured while buying land');
    }
  };

  setLandOnSale = async (id, sellingPrice) => {
    try {
      const response = await axiosApiClient.put(`${BASE_URL}/${id}`, { sellingPrice });

      if (response.status !== 200) {
        throw new Error();
      }
      const { data } = response.data;
      return data;
    } catch (err) {
      throw handleError(err, 'Error occured while setting land on sale');
    }
  };

  cancelSale = async id => {
    try {
      const response = await axiosApiClient.put(`${BASE_URL}-cancel/${id}`);

      if (response.status !== 200) {
        throw new Error();
      }
      const { data } = response.data;
      return data;
    } catch (err) {
      throw handleError(err, 'Error occured while canceling sale');
    }
  };

  getLandsInfo = async () => {
    try {
      const response = await axiosApiClient.get(`${BASE_URL}-info`);
      if (response.status !== 200) {
        throw new Error();
      }

      const { allTilesNumber, allLandsNumber, netWorth, propertyValueIncrease } = response.data;
      return { allTilesNumber, allLandsNumber, netWorth, propertyValueIncrease };
    } catch (err) {
      throw handleError(err, 'Error occured while fetching lands info');
    }
  };

  getCountries = async () => {
    try {
      const response = await axiosApiClient.get(`/countries`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { countries } = response.data.data;
      return countries;
    } catch (err) {
      throw handleError(err, 'Error occured while fetching countries');
    }
  };

  getPriceHistory = async () => {
    try {
      const response = await axiosApiClient.get(`/districts/price/increase`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { result } = response.data.data;
      return result;
    } catch (err) {
      throw handleError(err, 'Error occured while fetching countries');
    }
  };
}

export default new LandService();
