import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Images } from '../../themes';
import { Pagination, LandCard } from '../../components';
import { useLocation, useHistory } from 'react-router-dom';
import landService from '../../api/services/land';

const MyPortfolioScreen = () => {
  const user = useSelector(state => state.userInfo);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [perPage, setPerPage] = useState(1);

  const [totalTiles, setTotalTiles] = useState(0);
  const [totalLands, setTotalLands] = useState(0);
  const [netWorth, setNetWorth] = useState(0);
  const [propertyValueIncrease, setPropertyValueIncrease] = useState(0);
  let page = +useLocation().search.split('=')[1];

  const [data, setData] = useState([]);

  const history = useHistory();

  const setPage = async page => {
    setCurrentPage(page);
    history.push({
      pathname: '/my-portfolio',
      search: `?page=${page}`,
    });

    const response1 = await landService.getUsersLands(Number(page));
    setPerPage(response1.perPage);
    setTotalCount(response1.total);
    setData(response1.data);
  };

  useEffect(() => {
    async function fetchApi() {
      setCurrentPage(page);
      try {
        const response1 = await landService.getUsersLands(Number(page));
        setPerPage(response1.perPage);
        setTotalCount(response1.total);
        setData(response1.data);
      } catch (error) {
        console.log(error.message);
      }
    }
    if (page > 0) fetchApi();
  }, [page]);

  const fetchData = async () => {
    try {
      const response = await landService.getLandsInfo();
      setNetWorth(response.netWorth);
      setPropertyValueIncrease(response.propertyValueIncrease);
      setTotalTiles(response.allTilesNumber);
      setTotalLands(response.allLandsNumber);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onCardClick = landId => {
    history.push(`land/${landId}?page=1`);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="myportfolio__screen">
      <div className="myportfolio__screen__cover__photo__container">
        <img
          className="myportfolio__screen__cover__photo__container-cover__image"
          alt="coverPhoto"
          src={Images.planetHorizontal}
        />
        <img
          className="myportfolio__screen__cover__photo__container-image"
          alt="coverPhotoText"
          src={Images.coverPhotoText}
        />
      </div>
      <div className="myportfolio__screen__user__info__container">
        <div className="myportfolio__screen__user__info__container__avatar__container">
          {!user.profilePhoto ? (
            <span className="myportfolio__screen__user__info__container__avatar__container-text">
              {user.firstName[0]}
              {user.lastName[0]}
            </span>
          ) : (
            <img
              className="user__details__container__user__info__container__avatar__container-profile__photo"
              src={user.profilePhoto}
              alt="user_profile_photo"
            />
          )}
        </div>
        <div className="myportfolio__screen__user__info__container__basic__container">
          <div className="myportfolio__screen__user__info__container__basic__container__name__content">
            <p className="myportfolio__screen__user__info__container__basic__container__name__content-name">
              {user.firstName} {user.lastName}
            </p>
            <p className="myportfolio__screen__user__info__container__basic__container__name__content-username">
              @{user.username}
            </p>
          </div>
          <div className="myportfolio__screen__user__info__container__basic__container__land__content">
            <p className="myportfolio__screen__user__info__container__basic__container__land__content-lands">
              {totalLands}
            </p>
            <p className="myportfolio__screen__user__info__container__basic__container__land__content-name">
              Properties
            </p>
            <p className="myportfolio__screen__user__info__container__basic__container__land__content-tiles">
              {totalTiles}
            </p>
            <p className="myportfolio__screen__user__info__container__basic__container__land__content-name">Tiles</p>
            <p className="myportfolio__screen__user__info__container__basic__container__land__content-priceValue">
              E${netWorth.toFixed(2)}
            </p>
            <p className="myportfolio__screen__user__info__container__basic__container__land__content-name">
              Net Worth
            </p>
            <p className="myportfolio__screen__user__info__container__basic__container__land__content-priceValue">
              E${propertyValueIncrease.toFixed(2)}
            </p>
            <p className="myportfolio__screen__user__info__container__basic__container__land__content-name">
              Property Value Increase
            </p>
          </div>
        </div>
      </div>
      <div className="myportfolio__screen__lands__container">
        <p className="myportfolio__screen__lands__container-title">Portfolio</p>
        <div className="myportfolio__screen__lands__container__content">
          {data.map((land, index) => {
            return (
              <LandCard
                key={index + 1}
                index={index + 1}
                name={land.name}
                image={land.image}
                totalTiles={land.tiles}
                x={land.coordinates.x}
                y={land.coordinates.y}
                state={land.state}
                country={land.country}
                district={land.district}
                boughtValue={land.boughtValue}
                currentValue={land.marketPrice}
                onClick={() => onCardClick(land.id)}
              />
            );
          })}
        </div>
      </div>
      <div className="myportfolio__screen__pagination__container">
        <Pagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={perPage}
          onPageChange={page => setPage(page)}
        />
      </div>
    </div>
  );
};

export default MyPortfolioScreen;
