import {
  SET_LAND_ON_SALE_RESET,
  SET_LAND_ON_SALE_ATTEMPT,
  SET_LAND_ON_SALE_SUCCESS,
  SET_LAND_ON_SALE_ERROR,
  SET_LAND,
  CANCEL_SALE_RESET,
  CANCEL_SALE_ERROR,
  CANCEL_SALE_SUCCESS,
  CANCEL_SALE_ATTEMPT,
  BUY_LAND_FROM_USER_ATTEMPT,
  BUY_LAND_FROM_USER_SUCCESS,
  BUY_LAND_FROM_USER_ERROR,
  BUY_LAND_FROM_USER_RESET,
  BUY_LAND_ATTEMPT,
  BUY_LAND_SUCCESS,
  BUY_LAND_ERROR,
  BUY_LAND_RESET,
  SET_LAND_PART_ON_SALE_ATTEMPT,
  SET_LAND_PART_ON_SALE_SUCCESS,
  SET_LAND_PART_ON_SALE_ERROR,
  SET_LAND_PART_ON_SALE_RESET,
} from '../constants';

const INIT_STATE = {
  land: null,
  setLandOnSale: {
    attempt: false,
    success: false,
    error: null,
  },
  setLandPartOnSale: {
    attempt: false,
    success: false,
    error: null,
  },
  cancelSale: {
    attempt: false,
    success: false,
    error: null,
  },
  buyLandFromUser: {
    attempt: false,
    success: false,
    error: null,
  },
  buyLand: {
    attempt: false,
    success: false,
    error: null,
  },
};

const userReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LAND_ON_SALE_ATTEMPT:
      return {
        ...state,
        setLandOnSale: {
          attempt: true,
          success: false,
          error: null,
        },
      };
    case SET_LAND_ON_SALE_SUCCESS:
      return {
        ...state,
        setLandOnSale: {
          attempt: false,
          success: true,
          error: null,
        },
      };
    case SET_LAND_ON_SALE_ERROR:
      return {
        ...state,
        setLandOnSale: {
          attempt: false,
          success: false,
          error: action.payload,
        },
      };
    case SET_LAND_ON_SALE_RESET:
      return {
        ...state,
        setLandOnSale: {
          attempt: false,
          success: false,
          error: null,
        },
      };
    case CANCEL_SALE_ATTEMPT:
      return {
        ...state,
        cancelSale: {
          attempt: true,
          success: false,
          error: null,
        },
      };
    case CANCEL_SALE_SUCCESS:
      return {
        ...state,
        cancelSale: {
          attempt: false,
          success: true,
          error: null,
        },
      };
    case CANCEL_SALE_ERROR:
      return {
        ...state,
        cancelSale: {
          attempt: false,
          success: false,
          error: action.payload,
        },
      };
    case CANCEL_SALE_RESET:
      return {
        ...state,
        cancelSale: {
          attempt: false,
          success: false,
          error: null,
        },
      };
    case BUY_LAND_FROM_USER_ATTEMPT:
      return {
        ...state,
        buyLandFromUser: {
          attempt: true,
          success: false,
          error: null,
        },
      };
    case BUY_LAND_FROM_USER_SUCCESS:
      return {
        ...state,
        buyLandFromUser: {
          attempt: false,
          success: true,
          error: null,
        },
      };
    case BUY_LAND_FROM_USER_ERROR:
      return {
        ...state,
        buyLandFromUser: {
          attempt: false,
          success: false,
          error: action.payload,
        },
      };
    case BUY_LAND_FROM_USER_RESET:
      return {
        ...state,
        buyLandFromUser: {
          attempt: false,
          success: false,
          error: null,
        },
      };
    case BUY_LAND_ATTEMPT:
      return {
        ...state,
        buyLand: {
          attempt: true,
          success: false,
          error: null,
        },
      };
    case BUY_LAND_SUCCESS:
      return {
        ...state,
        buyLand: {
          attempt: false,
          success: true,
          error: null,
        },
      };
    case BUY_LAND_ERROR:
      return {
        ...state,
        buyLand: {
          attempt: false,
          success: false,
          error: action.payload,
        },
      };
    case BUY_LAND_RESET:
      return {
        ...state,
        buyLand: {
          attempt: false,
          success: false,
          error: null,
        },
      };
    case SET_LAND:
      return {
        ...state,
        land: action.payload,
      };
    case SET_LAND_PART_ON_SALE_ATTEMPT:
      return {
        ...state,
        setLandPartOnSale: {
          attempt: true,
          success: false,
          error: null,
        },
      };
    case SET_LAND_PART_ON_SALE_SUCCESS:
      return {
        ...state,
        setLandPartOnSale: {
          attempt: false,
          success: true,
          error: null,
        },
      };
    case SET_LAND_PART_ON_SALE_ERROR:
      return {
        ...state,
        setLandPartOnSale: {
          attempt: false,
          success: false,
          error: action.payload,
        },
      };
    case SET_LAND_PART_ON_SALE_RESET:
      return {
        ...state,
        setLandPartOnSale: {
          attempt: false,
          success: false,
          error: null,
        },
      };
    default:
      return state;
  }
};

export default userReducer;
