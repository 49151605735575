import React, { useEffect, useState } from 'react';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import { useDispatch, useSelector } from 'react-redux';
import { Button, PhoneCodeSelect } from '../../components';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { setInputPhone, setupPhone, setupPhoneInfoReset } from '../../store/actions/auth';
import { useHistory } from 'react-router-dom';

const PhoneForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [phoneCodes, setPhoneCodes] = useState([]);
  const [phone, setPhone] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [error, setError] = useState('');

  const setupPhoneAction = useSelector(state => state.auth.setupPhone);
  const email = useSelector(state => state.auth.inputEmail);

  useEffect(() => {
    if (email === '') {
      history.push('/auth/sign-up');
    }
    let phoneCodes = [];

    getCountries().forEach(country => {
      phoneCodes.push(getCountryCallingCode(country));
    });

    let uniqueCodes = [...new Set(phoneCodes)];
    const sorted = uniqueCodes.sort();
    setPhoneCodes(sorted);
    setPhoneCode(sorted[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onContinueClick = () => {
    let finalPhone = `+${phoneCode}${phone}`;
    if (!isValidPhoneNumber(finalPhone)) {
      setError('Invalid phone number');
      return;
    }

    setError('');
    dispatch(setupPhone(finalPhone));
  };

  useEffect(() => {
    let finalPhone = `+${phoneCode}${phone}`;
    if (setupPhoneAction.success) {
      dispatch(setupPhoneInfoReset());
      history.push('/auth/phone-verification');
      dispatch(setInputPhone(finalPhone));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setupPhoneAction, history, dispatch]);

  useEffect(() => {
    if (setupPhoneAction.error) {
      setError(setupPhoneAction.error.message);
      dispatch(setupPhoneInfoReset());
    }
  }, [setupPhoneAction, dispatch]);

  return (
    <>
      <p className="auth__screen__container-text">Enter your phone number</p>
      <PhoneCodeSelect phone={phone} setPhone={setPhone} setCode={setPhoneCode} phoneCodes={phoneCodes} />
      <div style={{ color: 'white' }}>{error}</div>
      <Button onClick={onContinueClick} title="Continue" className="auth__screen__container-button" />
    </>
  );
};

export default PhoneForm;
