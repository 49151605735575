import React, { useEffect, useState } from 'react';
import { UserInfoCard } from '../../components';
import userService from '../../api/services/user';

const LeaderboardScreen = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    async function fetchUsers() {
      try {
        const users = await userService.getLeaderboardUsers();

        setUsers(users);
      } catch (error) {
        console.log(error.message);
      }
    }

    fetchUsers();
  }, []);

  return (
    <div className="marketplace__screen">
      <div className="marketplace__screen__main__container">
        <p className="marketplace__screen-title">Leaderboard</p>

        <div className="marketplace__screen__table">
          <div className="marketplace__screen__header">
            <div className="marketplace__screen__col-1">
              <p className="marketplace__screen__col-title">Rank</p>
            </div>
            <div className="marketplace__screen__col-2">
              <p className="marketplace__screen__col-title">User</p>
            </div>
            <div className="marketplace__screen__col-3">
              <p className="marketplace__screen__col-title">Credits</p>
            </div>
          </div>
          {users.map((item, index) => (
            <LeaderboardItem key={item.id} item={item} index={index} />
          ))}
          <div className="marketplace__screen__footer" />
        </div>
      </div>
    </div>
  );
};

const LeaderboardItem = ({ item, index }) => {
  return (
    <div className="marketplace__screen__row">
      <div className="marketplace__screen__col-1">
        <p className="marketplace__screen__col-value">{index + 1}</p>
      </div>
      <div className="marketplace__screen__col-2">
        <div className="marketplace__screen__col-1">
          <UserInfoCard firstName={item.firstName} lastName={item.lastName} profilePhoto={item.profilePhoto} />
        </div>
      </div>
      <div className="marketplace__screen__col-3">
        <p className="marketplace__screen__col-price">E${item.credits.toFixed(2)}</p>
      </div>
    </div>
  );
};

export default LeaderboardScreen;
