import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button } from '..';
import { Input } from '..';
import { sendResetPasswordEmail, sendResetPasswordEmailReset } from '../../store/actions/auth';
import { isValidEmail } from '../../utils/validation';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  const sendResetPasswordEmailAction = useSelector(state => state.auth.sendResetPasswordMail);

  const onContinueClick = () => {
    if (!isValidEmail(email)) {
      setError('Invalid email address');
      return;
    }
    setError('');
    dispatch(sendResetPasswordEmail(email));
  };

  useEffect(() => {
    if (sendResetPasswordEmailAction.success) {
      history.push('/auth/reset-password');
      dispatch(sendResetPasswordEmailReset());
    }
  }, [sendResetPasswordEmailAction, history, dispatch]);

  useEffect(() => {
    if (sendResetPasswordEmailAction.error) {
      setError(sendResetPasswordEmailAction.error.message);
      dispatch(sendResetPasswordEmailReset());
    }
  }, [sendResetPasswordEmailAction, history, dispatch]);

  return (
    <>
      <p className="auth__screen__container-text">
        Enter your account's email address and we will send you a password reset code.
      </p>
      <Input type="text" className="auth__screen__container-input" placeholder="Email" onChange={setEmail} />
      <div style={{ color: 'white' }}>{error}</div>
      <Button onClick={onContinueClick} title="Continue" className="auth__screen__container-button" />
    </>
  );
}

export default ForgotPassword;
