import React from 'react';

const Button = ({ title, onClick, className, style, disabled }) => {
  let buttonClassNames = `buttonContainer ${className}`;
  if (disabled) {
    buttonClassNames += ' disabled';
  }
  return (
    <button disabled={disabled} style={style} className={buttonClassNames} onClick={onClick}>
      {title}
    </button>
  );
};

export default Button;
