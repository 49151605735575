// auth
export const SIGN_UP_ATTEMPT = 'SIGN_UP_ATTEMPT';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';
export const SIGN_UP_RESET = 'SIGN_UP_RESET';
export const LOGIN_ATTEMPT = 'LOGIN_ATTEMPT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_RESET = 'LOGIN_RESET';
export const VERIFY_EMAIL_ATTEMPT = 'VERIFY_EMAIL_ATTEMPT';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_ERROR = 'VERIFY_EMAIL_ERROR';
export const VERIFY_EMAIL_RESET = 'VERIFY_EMAIL_RESET';
export const RESEND_EMAIL_VERIFICATION_CODE_ATTEMPT = 'RESEND_EMAIL_VERIFICATION_CODE_ATTEMPT';
export const RESEND_EMAIL_VERIFICATION_CODE_SUCCESS = 'RESEND_EMAIL_VERIFICATION_CODE_SUCCESS';
export const RESEND_EMAIL_VERIFICATION_CODE_ERROR = 'RESEND_EMAIL_VERIFICATION_CODE_ERROR';
export const RESEND_EMAIL_VERIFICATION_CODE_RESET = 'RESEND_EMAIL_VERIFICATION_CODE_RESET';
export const VERIFY_PHONE_ATTEMPT = 'VERIFY_PHONE_ATTEMPT';
export const VERIFY_PHONE_SUCCESS = 'VERIFY_PHONE_SUCCESS';
export const VERIFY_PHONE_ERROR = 'VERIFY_PHONE_ERROR';
export const VERIFY_PHONE_RESET = 'VERIFY_PHONE_RESET';
export const RESEND_PHONE_VERIFICATION_CODE_ATTEMPT = 'RESEND_PHONE_VERIFICATION_CODE_ATTEMPT';
export const RESEND_PHONE_VERIFICATION_CODE_SUCCESS = 'RESEND_PHONE_VERIFICATION_CODE_SUCCESS';
export const RESEND_PHONE_VERIFICATION_CODE_ERROR = 'RESEND_PHONE_VERIFICATION_CODE_ERROR';
export const RESEND_PHONE_VERIFICATION_CODE_RESET = 'RESEND_PHONE_VERIFICATION_CODE_RESET';
export const SETUP_PHONE_ATTEMPT = 'SETUP_PHONE_ATTEMPT';
export const SETUP_PHONE_SUCCESS = 'SETUP_PHONE_SUCCESS';
export const SETUP_PHONE_ERROR = 'SETUP_PHONE_ERROR';
export const SETUP_PHONE_RESET = 'SETUP_PHONE_RESET';
export const LOGOUT = 'LOGOUT';
export const SET_INPUT_EMAIL = 'SET_INPUT_EMAIL';
export const SET_INPUT_PHONE = 'SET_INPUT_PHONE';
export const SEND_RESET_PASSWORD_EMAIL_ATTEMPT = 'SEND_RESET_PASSWORD_EMAIL_ATTEMPT';
export const SEND_RESET_PASSWORD_EMAIL_SUCCESS = 'SEND_RESET_PASSWORD_EMAIL_SUCCESS';
export const SEND_RESET_PASSWORD_EMAIL_ERROR = 'SEND_RESET_PASSWORD_EMAIL_ERROR';
export const SEND_RESET_PASSWORD_EMAIL_RESET = 'SEND_RESET_PASSWORD_EMAIL_RESET';
export const RESET_FORGOTTEN_PASSWORD_ATTEMPT = 'RESET_FORGOTTEN_PASSWORD_ATTEMPT';
export const RESET_FORGOTTEN_PASSWORD_SUCCESS = 'RESET_FORGOTTEN_PASSWORD_SUCCESS';
export const RESET_FORGOTTEN_PASSWORD_ERROR = 'RESET_FORGOTTEN_PASSWORD_ERROR';
export const RESET_FORGOTTEN_PASSWORD_RESET = 'RESET_FORGOTTEN_PASSWORD_RESET';
export const RESET_PASSWORD_ATTEMPT = 'RESET_PASSWORD_ATTEMPT';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_RESET = 'RESET_PASSWORD_RESET';
export const WITHDRAW_MONEY_ATTEMPT = 'WITHDRAW_MONEY_ATTEMPT';
export const WITHDRAW_MONEY_SUCCESS = 'WITHDRAW_MONEY_SUCCESS';
export const WITHDRAW_MONEY_ERROR = 'WITHDRAW_MONEY_ERROR';
export const WITHDRAW_MONEY_RESET = 'WITHDRAW_MONEY_RESET';

// user
export const FETCH_USER_INFO_SUCCESS = 'FETCH_USER_INFO_SUCCESS';
export const FETCH_USER_INFO_ERROR = 'FETCH_USER_INFO_ERROR';
export const POST_USERS_INFO_ATTEMPT = 'POST_USERS_INFO_ATTEMPT';
export const POST_USERS_INFO_SUCCESS = 'POST_USERS_INFO_SUCCESS';
export const POST_USERS_INFO_ERROR = 'POST_USERS_INFO_ERROR';
export const POST_USERS_INFO_RESET = 'POST_USERS_INFO_RESET';
export const SET_USER_INFO = 'SET_USER_INFO';
export const UPLOAD_PROFILE_PHOTO_ATTEMPT = 'UPLOAD_PROFILE_PHOTO_ATTEMPT';
export const UPLOAD_PROFILE_PHOTO_SUCCESS = 'UPLOAD_PROFILE_PHOTO_SUCCESS';
export const UPLOAD_PROFILE_PHOTO_ERROR = 'UPLOAD_PROFILE_PHOTO_ERROR';
export const UPLOAD_PROFILE_PHOTO_RESET = 'UPLOAD_PROFILE_PHOTO_RESET';
export const SET_USERS_ALL_INFO = 'SET_USERS_ALL_INFO';
export const DECREASE_BALANCE = 'DECREASE_BALANCE';
export const UPDATE_PROFILE_ATTEMPT = 'UPDATE_PROFILE_ATTEMPT';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';
export const UPDATE_PROFILE_RESET = 'UPDATE_PROFILE_RESET';

// land
export const SET_LAND_ON_SALE_ATTEMPT = 'SET_LAND_ON_SALE_ATTEMPT';
export const SET_LAND_ON_SALE_SUCCESS = 'SET_LAND_ON_SALE_SUCCESS';
export const SET_LAND_ON_SALE_ERROR = 'SET_LAND_ON_SALE_ERROR';
export const SET_LAND_ON_SALE_RESET = 'SET_LAND_ON_SALE_RESET';
export const SET_LAND = 'SET_LAND';
export const CANCEL_SALE_ATTEMPT = 'CANCEL_SALE_ATTEMPT';
export const CANCEL_SALE_SUCCESS = 'CANCEL_SALE_SUCCESS';
export const CANCEL_SALE_ERROR = 'CANCEL_SALE_ERROR';
export const CANCEL_SALE_RESET = 'CANCEL_SALE_RESET';
export const BUY_LAND_FROM_USER_ATTEMPT = 'BUY_LAND_FROM_USER_ATTEMPT';
export const BUY_LAND_FROM_USER_SUCCESS = 'BUY_LAND_FROM_USER_SUCCESS';
export const BUY_LAND_FROM_USER_ERROR = 'BUY_LAND_FROM_USER_ERROR';
export const BUY_LAND_FROM_USER_RESET = 'BUY_LAND_FROM_USER_RESET';
export const BUY_LAND_ATTEMPT = 'BUY_LAND_ATTEMPT';
export const BUY_LAND_SUCCESS = 'BUY_LAND_SUCCESS';
export const BUY_LAND_ERROR = 'BUY_LAND_ERROR';
export const BUY_LAND_RESET = 'BUY_LAND_RESET';
export const SET_LAND_PART_ON_SALE_ATTEMPT = 'SET_LAND_PART_ON_SALE_ATTEMPT';
export const SET_LAND_PART_ON_SALE_SUCCESS = 'SET_LAND_PART_ON_SALE_SUCCESS';
export const SET_LAND_PART_ON_SALE_ERROR = 'SET_LAND_PART_ON_SALE_ERROR';
export const SET_LAND_PART_ON_SALE_RESET = 'SET_LAND_PART_ON_SALE_RESET';
