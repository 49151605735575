import React from 'react';
import { Link, Switch, Route, useLocation, useRouteMatch } from 'react-router-dom';
import {
  SignInForm,
  EmailForm,
  VerificationForm,
  BasicInfoForm,
  ProfilePhoto,
  PhoneForm,
  ForgotPassword,
  ResetPassword,
} from '../../components';
import { Images, Icons } from '../../themes';

function AuthScreen() {
  let { path } = useRouteMatch();
  const location = useLocation();

  return (
    <div className="auth__screen">
      <div className="auth__screen__container">
        <p className="auth__screen__container-welcome__to__message">WELCOME TO</p>
        <img className="auth__screen__container-logo" src={Icons.eu} alt="logo" />
        <Switch>
          <Route path={`${path}/sign-in`}>
            <SignInForm />
          </Route>
          <Route path={`${path}/sign-up`}>
            <EmailForm />
          </Route>
          <Route path={`${path}/email-verification`}>
            <VerificationForm type="email" />
          </Route>
          <Route path={`${path}/phone-verification`}>
            <VerificationForm type="phone" />
          </Route>
          <Route path={`${path}/phone-setup`}>
            <PhoneForm />
          </Route>
          <Route path={`${path}/user-info`}>
            <BasicInfoForm />
          </Route>
          <Route path={`${path}/profile-photo`}>
            <ProfilePhoto />
          </Route>
          <Route path={`${path}/forgot-password`}>
            <ForgotPassword />
          </Route>
          <Route path={`${path}/reset-password`}>
            <ResetPassword />
          </Route>
        </Switch>
        <div className="auth__screen__container__additional__info__container">
          {location.pathname.includes('sign-in') ? (
            <>
              <p className="auth__screen__container__additional__info__container-text">Don’t have an account?</p>
              <Link to="/auth/sign-up" className="auth__screen__container__additional__info__container-link">
                Register
              </Link>
              <Link to="/auth/forgot-password" className="auth__screen__container__additional__info__container-link">
                Forgot Password?
              </Link>
            </>
          ) : (
            <>
              <p className="auth__screen__container__additional__info__container-text">Already have an account?</p>
              <Link to="/auth/sign-in" className="auth__screen__container__additional__info__container-link">
                Log in
              </Link>
            </>
          )}
        </div>
      </div>
      <div className="auth__screen__image__container">
        {location.pathname.includes('sign-in') ? (
          <img alt="auth_image" src={Images.loginPlanet} />
        ) : (
          <img alt="auth_image" src={Images.planetVertical} />
        )}
      </div>
    </div>
  );
}

export default AuthScreen;
