import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Input } from '../../components';
import { signUpWithEmail, signUpInfoReset, setInputEmail } from '../../store/actions/auth';
import { isValidEmail } from '../../utils/validation';

const EmailForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const signUpAction = useSelector(state => state.auth.signUp);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (signUpAction.success) {
      dispatch(signUpInfoReset());
      history.push('/auth/email-verification');
      dispatch(setInputEmail(email));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signUpAction, history, dispatch]);

  useEffect(() => {
    if (signUpAction.error) {
      setError(signUpAction.error.message);
      dispatch(signUpInfoReset());
    }
  }, [signUpAction, dispatch]);

  const onSignUp = () => {
    if (!isValidEmail(email)) {
      setError('Invalid email address');
      return;
    }
    if (!password || !passwordConfirmation) {
      setError('Both password fields must be filled.');
      return;
    }
    if (password.length < 6) {
      setError('Your password must contain at least 6 characters.');
      return;
    }
    if (password !== passwordConfirmation) {
      setError("Passwords doesn't match.");
      return;
    }

    setError('');
    dispatch(signUpWithEmail(email, password));
  };

  return (
    <>
      <p className="auth__screen__container-text">Sign up to Expansion Unlimited to continue</p>
      <Input type="text" className="auth__screen__container-input" onChange={setEmail} placeholder="Email" />
      <Input type="password" className="auth__screen__container-input" onChange={setPassword} placeholder="Password" />
      <Input
        type="password"
        className="auth__screen__container-input"
        onChange={setPasswordConfirmation}
        placeholder="Confirm Password"
      />
      <div style={{ color: 'white' }}>{error}</div>
      <Button title="Continue" onClick={onSignUp} className="auth__screen__container-button" />
    </>
  );
};

export default EmailForm;
