import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components';
import { uploadUserProfilePhoto, uploadUserProfilePhotoInfoReset } from '../../store/actions/user';
import Resizer from 'react-image-file-resizer';

const ProfilePhoto = () => {
  const firstName = useSelector(state => state.userInfo.firstName);
  const lastName = useSelector(state => state.userInfo.lastName);
  const imageInput = useRef();
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const uploadProfilePhotoAction = useSelector(state => state.userInfo.uploadProfilePhoto);
  const history = useHistory();

  const resizeFile = file =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        200,
        200,
        'JPEG',
        100,
        0,
        uri => {
          resolve(uri);
        },
        'file',
      );
    });

  const handleImagePreview = async e => {
    if (e.target.files.length !== 0) {
      let image_as_base64 = URL.createObjectURL(e.target.files[0]);
      let image_as_files = e.target.files[0];
      let image = await resizeFile(image_as_files);
      setImagePreview(image_as_base64);
      setImageFile(image);
    }
  };

  const onEditProfilePhotoClick = () => {
    imageInput.current.click();
  };

  const handleSubmitFile = () => {
    if (imageFile !== null) {
      let formData = new FormData();
      formData.append('file', imageFile);
      dispatch(uploadUserProfilePhoto(formData));
    }
    history.push('/earth?page=1');
  };

  useEffect(() => {
    if (uploadProfilePhotoAction.success) {
      history.push('/earth?page=1');
      dispatch(uploadUserProfilePhotoInfoReset());
    }
  }, [uploadProfilePhotoAction, history, dispatch]);

  useEffect(() => {
    if (uploadProfilePhotoAction.error) {
      setError(uploadProfilePhotoAction.error.message);
      dispatch(uploadUserProfilePhotoInfoReset());
    }
  }, [uploadProfilePhotoAction, dispatch]);

  return (
    <>
      <div className="auth__screen__container__avatar__container">
        <div className="auth__screen__container__avatar__container-avatar">
          {!imagePreview ? (
            <span className="auth__screen__container__avatar__container-avatar__text">
              {firstName[0]}
              {lastName[0]}
            </span>
          ) : (
            <img
              className="auth__screen__container__avatar__container-profile__photo"
              src={imagePreview}
              alt="avatar_preview"
            />
          )}
        </div>
        <input
          ref={imageInput}
          type="file"
          className="auth__screen__container__avatar__container-image__input"
          onChange={handleImagePreview}
        />
        <p className="auth__screen__container-edit__photo__text" onClick={onEditProfilePhotoClick}>
          Edit Profile Photo
        </p>
      </div>
      <div style={{ color: 'white' }}>{error}</div>
      <Button onClick={handleSubmitFile} title="Continue" className="auth__screen__container-button" />
    </>
  );
};

export default ProfilePhoto;
