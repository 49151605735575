import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Modal } from '../../components';
import userService from '../../api/services/user';
import { fetchUserInfo } from '../../store/actions/user';

const AddCreditScreen = () => {
  const [amount, setAmount] = useState(0);
  const user = useSelector(state => state.userInfo);
  const [error, setError] = useState('');
  const [url, setUrl] = useState(null);
  const modalRef = useRef();
  const dispatch = useDispatch();

  const addCreditClick = async () => {
    try {
      setError('');
      const url = await userService.getPaymentUrl({ amount: Number(amount) });
      setUrl(url);
      modalRef.current.show();
    } catch (error) {
      setError(error.message);
    }
  };

  const resetWorkModalInfo = () => {
    dispatch(fetchUserInfo());
    modalRef.current.hide();
  };

  return (
    <div className="add__credit__container">
      <div className="add__credit__container__content">
        <div className="add__credit__container__content__credit__info">
          <p className="add__credit__container__content__credit__info-amount">${parseFloat(user.balance).toFixed(2)}</p>
          <p className="add__credit__container__content__credit__info-credit">CREDIT</p>
        </div>
        <Input
          placeholder="Add Credit"
          value={amount}
          onChange={setAmount}
          className="add__credit__container__content-input"
        />
        <Button title="Add Credit" onClick={addCreditClick} className="add__credit__container__content-button" />
        <div style={{ color: 'white' }}>{error}</div>
      </div>
      <Modal ref={modalRef} title="Add Credit" maxWidth="60%" onClose={resetWorkModalInfo}>
        {url ? <iframe title="addcredit" src={url} width="100%" height="600px"></iframe> : null}
      </Modal>
    </div>
  );
};

export default AddCreditScreen;
