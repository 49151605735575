export const isValidEmail = email => {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isPositiveInteger = str => {
  str = str + '';
  if (!str) {
    return false;
  }
  let stringRepresentation = typeof str === 'number' ? str + '' : str;
  var n = Math.floor(Number(stringRepresentation));
  return n !== Infinity && String(n) === stringRepresentation && n > 0;
};

export const isCode = code => {
  if (!code) {
    return false;
  }
  if (code.length !== 6) {
    return false;
  }
  for (const num of code) {
    if (isNaN(+num)) {
      return false;
    }
  }
  return true;
};
