import React from 'react';

const LandCard = ({
  index,
  image,
  totalTiles,
  name,
  boughtValue,
  currentValue,
  x,
  y,
  state,
  country,
  district,
  onClick,
}) => {
  let classNameContainer = 'land__container';
  if (index % 3 === 0) {
    classNameContainer += ' last__in__row';
  }
  return (
    <div className={classNameContainer} onClick={onClick}>
      <div className="land__container__image__container">
        <img className="land__container__image__container__content" src={image} alt="land_photo" />
        <span className="land__container__image__container-total__tiles">{totalTiles} Tiles</span>
      </div>
      <div className="land__container__land__info">
        <p className="land__container__land__info-name">{name}</p>
        <div className="land__container__land__info__price__container">
          <p className="land__container__land__info__price__container-current__price">{currentValue.toFixed(2)}</p>
          <p className="land__container__land__info__price__container-bought__value">{boughtValue.toFixed(2)}</p>
        </div>
        <p className="land__container__land__info-coordinates">
          {x.toFixed(5)}, {y.toFixed(5)}
        </p>
        <p className="land__container__land__info-location">
          {country}, {state}, {district}
        </p>
      </div>
    </div>
  );
};

export default LandCard;
