import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Input } from '../../components';
import { resetForgottenPassword, resetForgottenPasswordReset } from '../../store/actions/auth';
import { isCode } from '../../utils/validation';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [code, setCode] = useState('');
  const [error, setError] = useState('');

  const email = useSelector(state => state.auth.inputEmail);

  const resetForgottenPasswordAction = useSelector(state => state.auth.resetForgottenPassword);

  useEffect(() => {
    if (resetForgottenPasswordAction.success) {
      history.push('/auth/sign-in');
      dispatch(resetForgottenPasswordReset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetForgottenPasswordAction, history, dispatch]);

  useEffect(() => {
    if (resetForgottenPasswordAction.error) {
      setError(resetForgottenPasswordAction.error.message);
      dispatch(resetForgottenPasswordReset());
    }
  }, [resetForgottenPasswordAction, dispatch]);

  const onContinueClick = () => {
    if (!password || !passwordConfirmation) {
      setError('Both password fields must be filled.');
      return;
    }
    if (password.length < 6) {
      setError('Your password must contain at least 6 characters.');
      return;
    }
    if (password !== passwordConfirmation) {
      setError("Passwords doesn't match.");
      return;
    }
    if (!isCode(code) || code.length !== 6) {
      setError('Code must contain 6 characters.');
      return;
    }

    setError('');
    dispatch(resetForgottenPassword(email, password, code));
  };

  return (
    <>
      <p className="auth__screen__container-text">Reset Password</p>
      <Input type="password" className="auth__screen__container-input" onChange={setPassword} placeholder="Password" />
      <Input
        type="password"
        className="auth__screen__container-input"
        onChange={setPasswordConfirmation}
        placeholder="Confirm Password"
      />
      <Input type="text" className="auth__screen__container-input" onChange={setCode} placeholder="6 digit code" />
      <div style={{ color: 'white' }}>{error}</div>
      <Button title="Continue" onClick={onContinueClick} className="auth__screen__container-button" />
    </>
  );
};

export default ResetPassword;
