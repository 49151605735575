import {
  POST_USERS_INFO_ATTEMPT,
  POST_USERS_INFO_SUCCESS,
  POST_USERS_INFO_ERROR,
  POST_USERS_INFO_RESET,
  SET_USER_INFO,
  SET_USERS_ALL_INFO,
  UPLOAD_PROFILE_PHOTO_ATTEMPT,
  UPLOAD_PROFILE_PHOTO_SUCCESS,
  UPLOAD_PROFILE_PHOTO_ERROR,
  UPLOAD_PROFILE_PHOTO_RESET,
  LOGOUT,
  DECREASE_BALANCE,
  UPDATE_PROFILE_ATTEMPT,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_RESET,
  RESET_PASSWORD_ATTEMPT,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_RESET,
  WITHDRAW_MONEY_ATTEMPT,
  WITHDRAW_MONEY_SUCCESS,
  WITHDRAW_MONEY_ERROR,
  WITHDRAW_MONEY_RESET,
} from '../constants';

const INIT_STATE = {
  id: null,
  username: null,
  email: null,
  firstName: null,
  lastName: null,
  balance: null,
  phone: null,
  referralCode: null,
  referralUserId: null,
  postUsersInfo: {
    attempt: false,
    success: false,
    error: null,
  },
  uploadProfilePhoto: {
    attempt: false,
    success: false,
    error: null,
  },
  updateProfile: {
    attempt: false,
    success: false,
    error: null,
  },
  resetPassword: {
    attempt: false,
    success: false,
    error: null,
  },
  withdrawMoney: {
    attempt: false,
    success: false,
    error: null,
  },
  lands: [],
};

const userReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_USER_INFO:
      const type = action.payload.type;
      const value = action.payload.value;
      return {
        ...state,
        [type]: value,
      };
    case SET_USERS_ALL_INFO:
      return {
        ...state,
        id: action.payload.id,
        username: action.payload.username,
        email: action.payload.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        balance: action.payload.balance,
        phone: action.payload.phone,
        profilePhoto: action.payload.profilePhoto,
        referralCode: action.payload.referralCode,
        referralUserId: action.payload.referralUserId,
        lands: action.payload.lands,
      };
    case LOGOUT:
      return {
        ...INIT_STATE,
      };
    case POST_USERS_INFO_ATTEMPT:
      return {
        ...state,
        postUsersInfo: {
          attempt: true,
          success: false,
          error: null,
        },
      };
    case POST_USERS_INFO_SUCCESS:
      return {
        ...state,
        postUsersInfo: {
          attempt: false,
          success: true,
          error: null,
        },
      };
    case POST_USERS_INFO_ERROR:
      return {
        ...state,
        postUsersInfo: {
          attempt: false,
          success: false,
          error: action.payload,
        },
      };
    case POST_USERS_INFO_RESET:
      return {
        ...state,
        postUsersInfo: {
          attempt: false,
          success: false,
          error: null,
        },
      };
    case UPLOAD_PROFILE_PHOTO_ATTEMPT:
      return {
        ...state,
        uploadProfilePhoto: {
          attempt: true,
          success: false,
          error: null,
        },
      };
    case UPLOAD_PROFILE_PHOTO_SUCCESS:
      return {
        ...state,
        uploadProfilePhoto: {
          attempt: false,
          success: true,
          error: null,
        },
      };
    case UPLOAD_PROFILE_PHOTO_ERROR:
      return {
        ...state,
        uploadProfilePhoto: {
          attempt: false,
          success: false,
          error: action.payload,
        },
      };
    case UPLOAD_PROFILE_PHOTO_RESET:
      return {
        ...state,
        uploadProfilePhoto: {
          attempt: false,
          success: false,
          error: null,
        },
      };
    case UPDATE_PROFILE_ATTEMPT:
      return {
        ...state,
        updateProfile: {
          attempt: true,
          success: false,
          error: null,
        },
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        updateProfile: {
          attempt: false,
          success: true,
          error: null,
        },
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        updateProfile: {
          attempt: false,
          success: false,
          error: action.payload,
        },
      };
    case UPDATE_PROFILE_RESET:
      return {
        ...state,
        updateProfile: {
          attempt: false,
          success: false,
          error: null,
        },
      };
    case DECREASE_BALANCE:
      return {
        ...state,
        balance: state.balance - action.payload,
      };
    case RESET_PASSWORD_ATTEMPT:
      return {
        ...state,
        resetPassword: {
          attempt: true,
          success: false,
          error: null,
        },
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: {
          attempt: false,
          success: true,
          error: null,
        },
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPassword: {
          attempt: false,
          success: false,
          error: action.payload,
        },
      };
    case RESET_PASSWORD_RESET:
      return {
        ...state,
        resetPassword: {
          attempt: false,
          success: false,
          error: null,
        },
      };
    case WITHDRAW_MONEY_ATTEMPT:
      return {
        ...state,
        withdrawMoney: {
          attempt: true,
          success: false,
          error: null,
        },
      };
    case WITHDRAW_MONEY_SUCCESS:
      return {
        ...state,
        withdrawMoney: {
          attempt: false,
          success: true,
          error: null,
        },
      };
    case WITHDRAW_MONEY_ERROR:
      return {
        ...state,
        withdrawMoney: {
          attempt: false,
          success: false,
          error: action.payload,
        },
      };
    case WITHDRAW_MONEY_RESET:
      return {
        ...state,
        withdrawMoney: {
          attempt: false,
          success: false,
          error: null,
        },
      };
    default:
      return state;
  }
};

export default userReducer;
