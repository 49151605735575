import React from 'react';

const RadioButton = ({ id, onClick, checked, label, value }) => {
  return (
    <div className="radio-item">
      <input type="radio" id={id} value={value} readOnly checked={checked} onClick={onClick} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default RadioButton;
