import React from 'react';

function UserInfoCard({ firstName, lastName, profilePhoto }) {
  return (
    <div className="user__info__container">
      <div className="user__info__container__avatar__container">
        {!profilePhoto ? (
          <span className="user__info__container__avatar__container-text">
            {firstName[0]}
            {lastName[0]}
          </span>
        ) : (
          <img
            className="user__info__container__avatar__container-profile__photo"
            src={profilePhoto}
            alt="user_profile_photo"
          />
        )}
      </div>
      <div className="user__info__container__name__container">
        <p className="user__info__container__name__container-text" style={{ paddingRight: 5 }}>
          {firstName}
        </p>
        <p className="user__info__container__name__container-text">{lastName}</p>
      </div>
    </div>
  );
}

export default UserInfoCard;
