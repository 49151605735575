import React, { useEffect, useRef, useState } from 'react';
import { Icons } from '../../themes';
import { Line } from 'react-chartjs-2';
import { Button, UserInfoCard, Pagination, Input, Modal } from '../../components';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { _MS_PER_DAY } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import landService from '../../api/services/land';
import {
  buyLandFromUser as buyLandFromUserAction,
  buyLandFromUserReset,
  cancelSale as cancelSaleAction,
  cancelSaleReset,
  setLand,
  setLandOnSale as setLandOnSaleAction,
  setLandOnSaleReset,
} from '../../store/actions/land';
import { fetchUserInfo } from '../../store/actions/user';

const LandScreen = () => {
  const user = useSelector(state => state.userInfo);
  const { land, setLandOnSale, cancelSale, buyLandFromUser } = useSelector(state => state.land);
  const loggedIn = useSelector(state => state.auth.loggedIn);

  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [chartMode, setChartMode] = useState('monthly');
  const [labels, setLabels] = useState(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug']);
  const modalRef = useRef();

  const [landName, setLandName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [perPage, setPerPage] = useState(1);
  const [events, setEvents] = useState([]);
  const [sellingPrice, setSellingPrice] = useState('');
  let page = +useLocation().search.split('=')[1];

  const onMonthlyClick = () => {
    setChartMode('monthly');
    setLabels(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug']);
  };

  const onYearlyClick = () => {
    setChartMode('yearly');
    setLabels(['2018', '2019', '2020', '2021']);
  };

  const setPage = async page => {
    setCurrentPage(page);
    history.push({
      pathname: `/land/${id}`,
      search: `?page=${page}`,
    });

    try {
      const response = await landService.getEventsByLand(id, Number(page));
      setPerPage(response.perPage);
      setTotalCount(response.total);
      setEvents(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    async function fetchApi() {
      setCurrentPage(page);
      try {
        const response = await landService.getEventsByLand(id, Number(page));
        setPerPage(response.perPage);
        setTotalCount(response.total);
        setEvents(response.data);
      } catch (error) {
        console.log(error.message);
      }
    }
    if (page > 0) fetchApi();
  }, [page, id]);

  useEffect(() => {
    if (setLandOnSale.error) {
      alert(setLandOnSale.error.message);
      modalRef.current.hide();
      dispatch(setLandOnSaleReset());
    }
  }, [setLandOnSale, dispatch]);

  useEffect(() => {
    if (setLandOnSale.success) {
      modalRef.current.hide();
      dispatch(setLandOnSaleReset());
      setSellingPrice();
    }
  }, [setLandOnSale, dispatch]);

  useEffect(() => {
    if (cancelSale.error) {
      alert(cancelSale.error.message);
      dispatch(cancelSaleReset());
    }
  }, [cancelSale, dispatch]);

  useEffect(() => {
    if (buyLandFromUser.success) {
      dispatch(fetchUserInfo());
      modalRef.current.hide();
      dispatch(buyLandFromUserReset());
      history.push('/my-portfolio?page=1');
    }
  }, [buyLandFromUser, history, dispatch]);

  useEffect(() => {
    if (buyLandFromUser.error) {
      alert(buyLandFromUser.error.message);
      modalRef.current.hide();
      dispatch(buyLandFromUserReset());
    }
  }, [buyLandFromUser, dispatch]);

  useEffect(() => {
    if (cancelSale.success) {
      modalRef.current.hide();
      dispatch(cancelSaleReset());
    }
  }, [cancelSale, dispatch]);

  const onBuyNowClick = () => {
    if (!loggedIn) {
      history.push('/auth/sign-in');
    } else {
      modalRef.current.show();
    }
  };

  const onConfirmBuyNowClick = () => {
    if (!loggedIn) {
      history.push('/auth/sign-in');
    } else {
      dispatch(buyLandFromUserAction(id, landName));
    }
  };

  const isSellLandButtonDisabled = () => {
    if (sellingPrice.trim().length) {
      return false;
    } else {
      return true;
    }
  };

  const onSellNowClick = () => {
    if (!loggedIn) {
      history.push('/auth/sign-in');
    } else {
      modalRef.current.show();
    }
  };

  const isConfirmButtonDisabled = () => {
    if (landName.trim().length) {
      return false;
    } else {
      return true;
    }
  };

  const onConfirmSellNowClick = () => {
    if (!loggedIn) {
      history.push('/auth/sign-in');
    } else {
      dispatch(setLandOnSaleAction(id, sellingPrice));
    }
  };

  const onCancelSaleClick = () => {
    if (!loggedIn) {
      history.push('/auth/sign-in');
    } else {
      modalRef.current.show();
    }
  };

  const onConfirmCancelSaleClick = () => {
    if (!loggedIn) {
      history.push('/auth/sign-in');
    } else {
      dispatch(cancelSaleAction(id));
    }
  };

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        let response = await landService.getLandById(id);
        dispatch(setLand(response));
      } catch (error) {
        console.log(error.message);
      }
    }

    fetchMyAPI();
  }, [dispatch, id]);

  const data = {
    labels: labels,
    datasets: [
      {
        data: [40, 70, 75, 85, 82, 86, 87, 100],
        fill: false,
        borderColor: '#466FFF',
        tension: 0.4,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    layout: {
      padding: 26,
    },
    scales: {
      x: {
        grid: {
          color: '#3D3D49',
        },
      },
      y: {
        ticks: {
          callback: function (value, index, values) {
            // for a value (tick) equals to 8

            return '$' + value;
            // 'junior-dev' will be returned instead and displayed on your chart
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Price History',
        color: 'white',
        align: 'start',
        font: {
          size: 16,
        },
        padding: {
          bottom: 26,
        },
      },
    },
  };

  return (
    <div className="land__screen">
      <div className="land__screen__main__container">
        {land && (
          <>
            <div className="land__screen__main__container__basic__info__container">
              <div className="land__screen__main__container__basic__info__container__image__container">
                <img
                  className="land__screen__main__container__basic__info__container__image__container-image"
                  src={land.image}
                  alt="land_photo"
                />
                <span className="land__screen__main__container__basic__info__container__image__container-text">
                  Saved for Mysor
                </span>
              </div>

              <div className="land__screen__main__container__basic__info__container__content">
                <div className="land__screen__main__container__basic__info__container__content__row">
                  <p className="land__screen__main__container__basic__info__container__content__row-key">Propert ID</p>
                  <p className="land__screen__main__container__basic__info__container__content__row-value">{land.id}</p>
                </div>
                <div
                  className="land__screen__main__container__basic__info__container__content__row"
                  style={{ padding: '1.1rem 1.6rem ' }}>
                  <p className="land__screen__main__container__basic__info__container__content__row-key">Owned by</p>
                  {land.user && (
                    <UserInfoCard
                      firstName={land.user?.firstName}
                      lastName={land.user?.lastName}
                      profilePhoto={land.user?.profilePhoto}
                    />
                  )}
                </div>
                <div className="land__screen__main__container__basic__info__container__content__row">
                  <p className="land__screen__main__container__basic__info__container__content__row-key">Land Title</p>
                  <p className="land__screen__main__container__basic__info__container__content__row-value">
                    {land.title}
                  </p>
                </div>
                <div className="land__screen__main__container__basic__info__container__content__row">
                  <p className="land__screen__main__container__basic__info__container__content__row-key">Tiles</p>
                  <p className="land__screen__main__container__basic__info__container__content__row-value">
                    {land.tiles}
                  </p>
                </div>
                <div className="land__screen__main__container__basic__info__container__content__row">
                  <p className="land__screen__main__container__basic__info__container__content__row-key">Location</p>
                  <p className="land__screen__main__container__basic__info__container__content__row-value">
                    {land.country}, {land.state}, {land.district}
                  </p>
                </div>
                <div
                  className="land__screen__main__container__basic__info__container__content__row"
                  style={{ borderBottom: 'unset' }}>
                  <p className="land__screen__main__container__basic__info__container__content__row-key">Coordinates</p>
                  <p className="land__screen__main__container__basic__info__container__content__row-value">
                    {land.coordinates?.x.toFixed(5)}, {land.coordinates?.y.toFixed(5)}
                  </p>
                </div>
              </div>
            </div>
            <div className="land__screen__main__container__price__container">
              <div className="land__screen__main__container__price__container__history">
                <Line data={data} options={options} />
                <div className="land__screen__main__container__price__container__history-chart__mode">
                  <p
                    className={
                      chartMode === 'monthly'
                        ? 'land__screen__main__container__price__container__history-chart__mode-selected__text'
                        : 'land__screen__main__container__price__container__history-chart__mode-text'
                    }
                    onClick={onMonthlyClick}>
                    Monthly
                  </p>
                  <p
                    className={
                      chartMode === 'yearly'
                        ? 'land__screen__main__container__price__container__history-chart__mode-selected__text'
                        : 'land__screen__main__container__price__container__history-chart__mode-text'
                    }
                    onClick={onYearlyClick}>
                    Yearly
                  </p>
                </div>
              </div>
              <div className="land__screen__main__container__price__container__info">
                <div className="land__screen__main__container__price__container__info__content">
                  <div className="land__screen__main__container__price__container__info__content-bought__value">
                    <img src={Icons.boughtValue} alt="bought-value" />
                    <div style={{ paddingLeft: 10 }}>
                      <p className="land__screen__main__container__price__container__info__content-value">
                        ${land.boughtValue?.toFixed(2)}
                      </p>
                      <p className="land__screen__main__container__price__container__info__content-text">Purchased</p>
                    </div>
                  </div>
                  <div className="land__screen__main__container__price__container__info__content-price__value">
                    <img src={Icons.priceValue} alt="price-value" />
                    <div style={{ paddingLeft: 10 }}>
                      {user.username === land.user?.username ? (
                        land.onSale ? (
                          <>
                            <p className="land__screen__main__container__price__container__info__content-value">
                              {`$${land.sellingPrice?.toFixed(2)}`}
                            </p>
                            <p className="land__screen__main__container__price__container__info__content-text">
                              Price Value
                            </p>
                          </>
                        ) : (
                          <Input
                            className="land__screen__main__container__price__container__info__content-input"
                            value={sellingPrice}
                            onChange={setSellingPrice}
                            placeholder="Price"
                          />
                        )
                      ) : (
                        <>
                          <p className="land__screen__main__container__price__container__info__content-value">
                            {`$${land.sellingPrice?.toFixed(2)}`}
                          </p>
                          <p className="land__screen__main__container__price__container__info__content-text">
                            Price Value
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="land__screen__main__container__price__container__info__content-market__value">
                    <img src={Icons.marketValue} alt="market-value" />
                    <div style={{ paddingLeft: 10 }}>
                      <div className="land__screen__main__container__price__container__info__content-market__value-content">
                        <p className="land__screen__main__container__price__container__info__content-value">
                          ${land.marketPrice?.toFixed(2)}
                        </p>
                        <p className="land__screen__main__container__price__container__info__content-value__percentage">
                          {((land.marketPrice / land.boughtValue) * 100 - 100).toFixed(2)}%
                        </p>
                      </div>
                      <p className="land__screen__main__container__price__container__info__content-text">
                        Market Value
                      </p>
                    </div>
                  </div>
                </div>
                {user.username === land.user?.username ? (
                  land.onSale ? (
                    <>
                      <Button onClick={onCancelSaleClick} title="Revoke from sale" />
                      <Modal
                        ref={modalRef}
                        onClose={() => modalRef.current.hide()}
                        title="Revoke from sale"
                        maxWidth="500px">
                        <p className="land__screen__main__container__price__container__modal-text">
                          Are you sure you want to revoke land from the sale?
                        </p>
                        <div className="land__screen__main__container__price__container__modal__button__container">
                          <Button
                            title="NO"
                            onClick={() => modalRef.current.hide()}
                            style={{ marginRight: '2rem' }}
                            className="land__screen__main__container__price__container__modal__button__container-btn"
                          />
                          <Button
                            title="YES"
                            onClick={onConfirmCancelSaleClick}
                            className="land__screen__main__container__price__container__modal__button__container-btn"
                          />
                        </div>
                      </Modal>
                    </>
                  ) : (
                    <>
                      <Button disabled={isSellLandButtonDisabled()} onClick={onSellNowClick} title="Sell land" />
                      <Modal ref={modalRef} onClose={() => modalRef.current.hide()} title="Sell land" maxWidth="500px">
                        <p className="land__screen__main__container__price__container__modal-text">
                          Are you sure you want to put it up for sale?
                        </p>
                        <div className="land__screen__main__container__price__container__modal__button__container">
                          <Button
                            title="NO"
                            onClick={() => modalRef.current.hide()}
                            style={{ marginRight: '2rem' }}
                            className="land__screen__main__container__price__container__modal__button__container-btn"
                          />
                          <Button
                            title="YES"
                            onClick={onConfirmSellNowClick}
                            className="land__screen__main__container__price__container__modal__button__container-btn"
                          />
                        </div>
                      </Modal>
                    </>
                  )
                ) : (
                  <>
                    <Button onClick={onBuyNowClick} title="Buy now" />
                    <Modal ref={modalRef} onClose={() => modalRef.current.hide()} title="Buy land" maxWidth="500px">
                      <p className="land__screen__main__container__price__container__modal-text">
                        Enter the land name and confirm the purchase!
                      </p>
                      <Input
                        type="text"
                        className="land__screen__main__container__price__container__modal-input"
                        onChange={setLandName}
                        placeholder="Land name"
                      />
                      <div className="land__screen__main__container__price__container__modal__button__container">
                        <Button
                          title="Cancel"
                          onClick={() => modalRef.current.hide()}
                          style={{ marginRight: '2rem' }}
                          className="land__screen__main__container__price__container__modal__button__container-btn"
                        />
                        <Button
                          disabled={isConfirmButtonDisabled()}
                          title="Confirm"
                          onClick={onConfirmBuyNowClick}
                          className="land__screen__main__container__price__container__modal__button__container-btn"
                        />
                      </div>
                    </Modal>
                  </>
                )}
              </div>
            </div>
          </>
        )}

        <div className="land__screen__main__container__event__container">
          <div className="marketplace__screen__table" style={{ marginBottom: 20 }}>
            <div className="marketplace__screen__header">
              <div className="marketplace__screen__col-1">
                <p className="marketplace__screen__col-title">Event</p>
              </div>
              <div className="marketplace__screen__col-1">
                <p className="marketplace__screen__col-title">Price</p>
              </div>
              <div className="marketplace__screen__col-1">
                <p className="marketplace__screen__col-title">From</p>
              </div>
              <div className="marketplace__screen__col-1">
                <p className="marketplace__screen__col-title">To</p>
              </div>
              <div className="marketplace__screen__col-1">
                <p className="marketplace__screen__col-title">Date</p>
              </div>
            </div>
            {events.map(item => (
              <EventItem key={item.id} item={item} />
            ))}
            <div className="marketplace__screen__footer" style={{ height: '2.5rem' }} />
          </div>
        </div>
        <div className="land__screen__main__container__pagination__container">
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={perPage}
            onPageChange={page => setPage(page)}
          />
        </div>
      </div>
    </div>
  );
};

const dateDiffInDays = (a, b) => {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};
const EventItem = ({ item }) => (
  <div className="marketplace__screen__row" style={{ height: '5rem' }}>
    <div className="marketplace__screen__col-1">
      <p className="marketplace__screen__col-value">{item.type}</p>
    </div>
    <div className="marketplace__screen__col-1">
      <p className="marketplace__screen__col-value">${item.price.toFixed(2)}</p>
    </div>
    <div className="marketplace__screen__col-1">
      {item.sellers && (
        <UserInfoCard
          firstName={item.sellers.firstName}
          lastName={item.sellers.lastName}
          profilePhoto={item.sellers.profilePhoto}
        />
      )}
    </div>
    <div className="marketplace__screen__col-1">
      <UserInfoCard
        firstName={item.buyers.firstName}
        lastName={item.buyers.lastName}
        profilePhoto={item.buyers.profilePhoto}
      />
    </div>
    <div className="marketplace__screen__col-1">
      <p className="marketplace__screen__col-value">
        {dateDiffInDays(new Date(+item.date), new Date()) === 0
          ? 'today'
          : dateDiffInDays(new Date(+item.date), new Date()) + ' days ago'}
      </p>
    </div>
  </div>
);

export default LandScreen;
