import axiosApiClient from '..';
import { handleError } from '../../utils/errors';

const BASE_URL = '/users';

class UserService {
  // Fetch user's info
  fetchUserInfo = async () => {
    try {
      const response = await axiosApiClient.get('/me');
      if (response.status !== 200) {
        throw new Error();
      }
      const { user } = response.data.data;
      return user;
    } catch (err) {
      throw handleError(err, "Error occured while fetching user's info.");
    }
  };

  getLeaderboardUsers = async () => {
    try {
      const response = await axiosApiClient.get('/users/leaderboard');
      if (response.status !== 200) {
        throw new Error();
      }
      const { users } = response.data.data;
      return users;
    } catch (err) {
      throw handleError(err, 'Error occured while fetching users.');
    }
  };

  updateProfile = async formData => {
    try {
      const response = await axiosApiClient.put(`${BASE_URL}/update-profile`, formData);
      if (response.status !== 200) {
        throw new Error();
      }
      const { user } = response.data.data;
      return user;
    } catch (err) {
      throw handleError(err, "Error occured while updating user's profile.");
    }
  };

  resetPassword = async data => {
    try {
      const response = await axiosApiClient.put(`${BASE_URL}/reset-password`, data);
      if (response.status !== 200) {
        throw new Error();
      }
      const { message } = response.data;
      return message;
    } catch (err) {
      throw handleError(err, "Error occured while resetting user's password.");
    }
  };

  withdrawMoney = async amount => {
    try {
      const response = await axiosApiClient.put(`${BASE_URL}/withdraw?amount=${amount}`);
      if (response.status !== 200) {
        throw new Error();
      }
      const { message } = response.data;
      return message;
    } catch (err) {
      throw handleError(err, 'Error occured while withdrawing money.');
    }
  };

  // Post user's info
  postUsersInfo = async userInfo => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/info`, userInfo);
      if (response.status !== 201) {
        throw new Error();
      }
    } catch (err) {
      throw handleError(err, "Error occured while posting user's info");
    }
  };

  getPaymentUrl = async amount => {
    try {
      const response = await axiosApiClient.post(`${BASE_URL}/get-payment`, amount);
      if (response.status !== 201) {
        throw new Error();
      }
      const { url } = response.data.data;
      return url;
    } catch (err) {
      throw handleError(err, 'Error occured while getting payment url');
    }
  };

  // Upload user's profile photo
  uploadUserProfilePhoto = async formData => {
    try {
      const headers = { 'Content-Type': 'multipart/form-data' };
      const response = await axiosApiClient.post(`${BASE_URL}/upload/profile-image`, formData, { headers });
      if (response.status !== 201) {
        throw new Error();
      }
      const { user } = response.data.data;
      return user;
    } catch (err) {
      throw handleError(err, "Error occured while posting user's profile photo");
    }
  };
}

export default new UserService();
