import landService from '../../api/services/land';
import {
  BUY_LAND_ATTEMPT,
  BUY_LAND_ERROR,
  BUY_LAND_FROM_USER_ATTEMPT,
  BUY_LAND_FROM_USER_ERROR,
  BUY_LAND_FROM_USER_RESET,
  BUY_LAND_FROM_USER_SUCCESS,
  BUY_LAND_RESET,
  BUY_LAND_SUCCESS,
  CANCEL_SALE_ATTEMPT,
  CANCEL_SALE_ERROR,
  CANCEL_SALE_RESET,
  CANCEL_SALE_SUCCESS,
  SET_LAND,
  SET_LAND_ON_SALE_ATTEMPT,
  SET_LAND_ON_SALE_ERROR,
  SET_LAND_ON_SALE_RESET,
  SET_LAND_ON_SALE_SUCCESS,
  SET_LAND_PART_ON_SALE_ATTEMPT,
  SET_LAND_PART_ON_SALE_ERROR,
  SET_LAND_PART_ON_SALE_RESET,
  SET_LAND_PART_ON_SALE_SUCCESS,
} from '../constants';

export const setLandOnSale = (id, sellingPrice) => {
  return async dispatch => {
    try {
      dispatch({ type: SET_LAND_ON_SALE_ATTEMPT });
      const land = await landService.setLandOnSale(id, +sellingPrice);
      dispatch({ type: SET_LAND_ON_SALE_SUCCESS });
      dispatch({ type: SET_LAND, payload: land });
    } catch (err) {
      dispatch({ type: SET_LAND_ON_SALE_ERROR, payload: err });
    }
  };
};

export const setLandOnSaleReset = () => ({ type: SET_LAND_ON_SALE_RESET });

export const cancelSale = (id, sellingPrice) => {
  return async dispatch => {
    try {
      dispatch({ type: CANCEL_SALE_ATTEMPT });
      const land = await landService.cancelSale(id);
      dispatch({ type: CANCEL_SALE_SUCCESS });
      dispatch({ type: SET_LAND, payload: land });
    } catch (err) {
      dispatch({ type: CANCEL_SALE_ERROR, payload: err });
    }
  };
};

export const cancelSaleReset = () => ({ type: CANCEL_SALE_RESET });

export const buyLandFromUser = (id, landName) => {
  return async dispatch => {
    try {
      dispatch({ type: BUY_LAND_FROM_USER_ATTEMPT });
      await landService.buyLandFromUser(id, landName);
      dispatch({ type: BUY_LAND_FROM_USER_SUCCESS });
    } catch (err) {
      dispatch({ type: BUY_LAND_FROM_USER_ERROR, payload: err });
    }
  };
};

export const buyLandFromUserReset = () => ({ type: BUY_LAND_FROM_USER_RESET });

export const buyLand = formData => {
  return async dispatch => {
    try {
      dispatch({ type: BUY_LAND_ATTEMPT });
      await landService.buyLand(formData);
      dispatch({ type: BUY_LAND_SUCCESS });
    } catch (err) {
      dispatch({ type: BUY_LAND_ERROR, payload: err });
    }
  };
};

export const buyLandReset = () => ({ type: BUY_LAND_RESET });

export const setLand = lend => ({ type: SET_LAND, payload: lend });

export const setLandPartOnSale = formData => {
  return async dispatch => {
    try {
      dispatch({ type: SET_LAND_PART_ON_SALE_ATTEMPT });
      await landService.sellLand(formData);
      dispatch({ type: SET_LAND_PART_ON_SALE_SUCCESS });
    } catch (err) {
      dispatch({ type: SET_LAND_PART_ON_SALE_ERROR, payload: err });
    }
  };
};

export const setLandPartOnSaleReset = () => ({ type: SET_LAND_PART_ON_SALE_RESET });
