import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { InputCode, Button } from '..';
import {
  resendVerificatioCodePhone,
  resendVerificatioCodePhoneInfoReset,
  resendVerificationCodeEmail,
  resendVerificationCodeEmailInfoReset,
  verifyEmail,
  verifyEmailInfoReset,
  verifyPhone,
  verifyPhoneInfoReset,
} from '../../store/actions/auth';
import { isCode } from '../../utils/validation';

function VerificationForm({ type }) {
  const [code, setCode] = useState('');
  const [attempts, setAttempts] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState('');

  const verifyEmailAction = useSelector(state => state.auth.verifyEmail);
  const verifyPhoneAction = useSelector(state => state.auth.verifyPhone);

  const resendEmailCode = useSelector(state => state.auth.resendEmailCode);
  const resendPhoneCode = useSelector(state => state.auth.resendPhoneCode);

  const email = useSelector(state => state.auth.inputEmail);
  const phone = useSelector(state => state.auth.inputPhone);

  useEffect(() => {
    if (type === 'phone') {
      if (phone === '') {
        history.push('/auth/phone-setup');
      }
    }

    if (type === 'email') {
      if (email === '') {
        history.push('/auth/sign-up');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verify = () => {
    if (!isCode(code) || code.length !== 6) {
      setError('Code must contain 6 numbers.');
      return;
    }

    if (type === 'email') {
      setError('');
      dispatch(verifyEmail(email, code));
    } else {
      setError('');
      dispatch(verifyPhone(phone, code));
    }
  };

  useEffect(() => {
    if (verifyPhoneAction.success) {
      history.push('/auth/user-info');
      dispatch(verifyPhoneInfoReset());
    }
  }, [verifyPhoneAction, history, dispatch]);

  useEffect(() => {
    if (verifyPhoneAction.error) {
      setError(verifyPhoneAction.error.message);
      dispatch(verifyPhoneInfoReset());
    }
  }, [verifyPhoneAction, dispatch]);

  useEffect(() => {
    if (verifyEmailAction.success) {
      history.push('/auth/phone-setup');
      dispatch(verifyEmailInfoReset());
    }
  }, [verifyEmailAction, history, dispatch]);

  useEffect(() => {
    if (verifyEmailAction.error) {
      setError(verifyEmailAction.error.message);
      dispatch(verifyEmailInfoReset());
    }
  }, [verifyEmailAction, dispatch]);

  useEffect(() => {
    if (resendPhoneCode.success) {
      setAttempts(prev => prev + 1);
      dispatch(resendVerificatioCodePhoneInfoReset());
    }
  }, [resendPhoneCode, dispatch]);

  const resendCode = () => {
    if (type === 'email') {
      if (attempts > 2) {
        return;
      }

      dispatch(resendVerificationCodeEmail(email));
    } else {
      if (attempts > 2) {
        return;
      }

      dispatch(resendVerificatioCodePhone(phone));
    }
  };

  useEffect(() => {
    if (resendEmailCode.success) {
      setAttempts(prev => prev + 1);
      dispatch(resendVerificationCodeEmailInfoReset());
    }
  }, [resendEmailCode, dispatch]);

  useEffect(() => {
    if (resendPhoneCode.success) {
      setAttempts(prev => prev + 1);
      dispatch(resendVerificatioCodePhoneInfoReset());
    }
  }, [resendPhoneCode, dispatch]);

  return (
    <>
      <p className="auth__screen__container-verification__text">Enter verification code</p>
      <p className="auth__screen__container-verification__text">
        {type === 'phone' ? `Your number: ${phone}` : `Your email: ${email}`}
      </p>
      <InputCode value={code} setValue={setCode} length={6} />
      <p className="auth__screen__container-code__text">
        Didn’t get code?{' '}
        <span onClick={resendCode} className="auth__screen__container-try__again__text">
          Try Again
        </span>
      </p>
      <div style={{ color: 'white' }}>{error}</div>
      <Button onClick={verify} title="Continue" className="auth__screen__container-verification__button" />
    </>
  );
}

export default VerificationForm;
