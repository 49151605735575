import React from 'react';
import { Input } from '../../components';

const PhoneCodeSelect = ({ setPhone, phoneCodes, setCode }) => {
  return (
    <div className="select__number__container">
      <select className="select__number__container-select" onChange={e => setCode(e.target.value)}>
        {phoneCodes.map(code => (
          <option key={code} value={code}>
            +{code}
          </option>
        ))}
      </select>
      <Input type="tel" placeholder="Phone Number" onChange={setPhone} />
    </div>
  );
};

export default PhoneCodeSelect;
