/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import axios from 'axios';
import { Icons } from '../../themes';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import tileService from '../../api/services/tile';
import landService from '../../api/services/land';
import { Button, Input, Modal, Pagination, RadioButton, SelectInput } from '../../components';
import { decreaseUserBalance, fetchUserInfo } from '../../store/actions/user';
import { buyLand, buyLandReset, setLandPartOnSale, setLandPartOnSaleReset } from '../../store/actions/land';
import { ReactComponent as Loader } from '../../assets/icons/loader.svg';
import { _REFERRAL_CODE_DISCOUNT } from '../../utils/constants';
import turfDistance from '@turf/distance';
import turfPoint from 'turf-point';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

mapboxgl.accessToken =
  'pk.eyJ1IjoiZXhwYW5zaW9udW5saW1pdGVkIiwiYSI6ImNrcTJsOGV6ejBhcHQydm12Z2VqamMzdHAifQ.kEHOMT_HrhHYp799WX0rdA';

const MAX_SELECTED_TILES = 750;

const MapBox = () => {
  const history = useHistory();
  const loggedIn = useSelector(state => state.auth.loggedIn);
  const buyLandAction = useSelector(state => state.land.buyLand);
  const setLandPartOnSaleAction = useSelector(state => state.land.setLandPartOnSale);

  const user = useSelector(state => state.userInfo);

  const dispatch = useDispatch();
  const modalRef = useRef();
  const modalSellLandRef = useRef();
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [lng, setLng] = useState(-118.3622);
  const [lat, setLat] = useState(34.0892);
  const [zoom, setZoom] = useState(16);
  const [mapSyle, setMapSyle] = useState('satellite-v9');
  const [error, setError] = useState('');

  const [stateSearchOptions, setStateSearchOptions] = useState([]);
  const [stateSearch, setStateSearch] = useState('');
  const [districtSearchOptions, setDistrictSearchOptions] = useState([]);
  const [districtSearch, setDistrictSearch] = useState('');
  const [countrySearchOptions, setCountrySearchOptions] = useState([]);
  const [countrySearch, setCountrySearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingPrice, setLoadingPrice] = useState(false);

  const [landName, setLandName] = useState('');
  const [sellingPrice, setSellingPrice] = useState('');

  const [district, setDistrict] = useState();
  const [region, setRegion] = useState();
  const [country, setCountry] = useState();
  const landImage = useRef(null);

  const [selectedTilesNumber, setSelectedTilesNumber] = useState(0);
  const tilePrice = useRef(0);

  const canvas = useRef();
  const startPosition = useRef();

  const boughtTiles = useRef([]);
  const selectedTiles = useRef([]);
  const selectedBoughtTiles = useRef([]);
  const selectedBbox = useRef(null);

  const boughtLand = useRef(null);

  const boughtTilesIcons = useRef({
    type: 'FeatureCollection',
    features: [],
  });

  const geocoder = new MapboxGeocoder({
    accessToken: mapboxgl.accessToken,
    mapboxgl: mapboxgl,
  });

  const tileLng = useRef();
  const tileLat = useRef();

  let y = +useLocation().search?.split('&')[2]?.split('=')[1];
  let x = +useLocation().search?.split('&')[1]?.split('=')[1];

  const [lands, setLands] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [perPage, setPerPage] = useState(1);
  let page = +useLocation().search.split('=')[1].split('&')[0];
  const [countriesData, setCountriesData] = useState([]);

  const setPage = async page => {
    setCurrentPage(page);
    history.push({
      pathname: '/earth',
      search: `?page=${page}`,
    });

    try {
      const response1 = await landService.getLandsOnSale(Number(page), countrySearch, stateSearch, districtSearch);
      setPerPage(response1.perPage);
      setTotalCount(response1.total);
      setLands(response1.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    async function fetchApi() {
      setCurrentPage(page);
      try {
        const response1 = await landService.getLandsOnSale(Number(page), countrySearch, stateSearch, districtSearch);
        setPerPage(response1.perPage);
        setTotalCount(response1.total);
        setLands(response1.data);
      } catch (error) {
        console.log(error.message);
      }
    }
    if (page > 0) fetchApi();
  }, [page, countrySearch, stateSearch, districtSearch]);

  useEffect(() => {
    const selectedCountry = countriesData.find(country => country.name === countrySearch);
    const states = [];
    const districts = [];
    if (selectedCountry) {
      if (!selectedCountry.states.some(countryState => countryState.name === stateSearch)) {
        setStateSearch('');
      }
      if (
        !selectedCountry.states.some(countryState => {
          return countryState.districts.some(stateDistrict => stateDistrict.name === districtSearch);
        })
      ) {
        setDistrictSearch('');
      }

      const selectedState = selectedCountry.states.find(state => state.name === stateSearch);
      selectedCountry.states.forEach(state => {
        states.push(state.name);
        if (!selectedState) {
          state.districts.forEach(district => {
            districts.push(district.name);
          });
        }
      });
      if (selectedState) {
        if (!selectedState.districts.some(stateDistrict => stateDistrict.name === districtSearch)) {
          setDistrictSearch('');
        }
        selectedState.districts.forEach(district => {
          districts.push(district.name);
        });
      }
      setStateSearchOptions(states);
      setDistrictSearchOptions(districts);
    } else {
      let selectedState = null;
      for (let c of countriesData) {
        for (let s of c.states) {
          if (s.name === stateSearch) selectedState = s;
        }
      }
      countriesData.forEach(country => {
        country.states.forEach(state => {
          states.push(state.name);
          if (!selectedState) {
            state.districts.forEach(district => {
              districts.push(district.name);
            });
          }
        });
      });
      if (selectedState) {
        if (!selectedState.districts.some(stateDistrict => stateDistrict.name === districtSearch)) {
          setDistrictSearch('');
        }
        selectedState.districts.forEach(district => {
          districts.push(district.name);
        });
      }
      setStateSearchOptions(states);
      setDistrictSearchOptions(districts);
    }
  }, [countrySearch, stateSearch]);

  useEffect(() => {
    async function fetchCountries() {
      try {
        const countries = await landService.getCountries();
        setCountriesData(countries);
        setCountrySearchOptions(countries.map(country => country.name));
        const states = [];
        const districts = [];

        countries.forEach(country => {
          country.states.forEach(state => {
            states.push(state.name);
            state.districts.forEach(district => {
              districts.push(district.name);
            });
          });
        });
        setStateSearchOptions(states);
        setDistrictSearchOptions(districts);
      } catch (error) {
        console.log(error.message);
      }
    }

    fetchCountries();

    if (x && y) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/' + mapSyle,
        center: [y, x],
        zoom: 17,
      });
      map.current.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
      setTimeout(() => {
        map.current.flyTo({ center: [y, x], zoom: 18 });
      }, 500);
      initListeners();
    } else {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/' + mapSyle,
        center: [lng, lat],
        zoom: zoom,
      });
      map.current.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
      initListeners();
    }
  }, []);

  useEffect(() => {
    if (x && y) {
      setTimeout(() => {
        map.current.flyTo({ center: [y, x], zoom: 18 });
      }, 500);
    }
  }, [x, y]);

  useEffect(() => {
    if (buyLandAction.error) {
      setError(buyLandAction.error.message);
      setLoading(false);
      dispatch(buyLandReset());
    }
  }, [buyLandAction.error, dispatch]);

  useEffect(() => {
    if (setLandPartOnSaleAction.error) {
      setError(setLandPartOnSaleAction.error.message);
      setLoading(false);
      dispatch(setLandPartOnSaleReset());
    }
  }, [setLandPartOnSaleAction.error, dispatch]);

  const stringToHslColor = (str, s, l) => {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;

    l /= 100;
    const a = (s * Math.min(l, 1 - l)) / 100;
    const f = n => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, '0'); // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  };

  useEffect(() => {
    if (buyLandAction.success) {
      setLoading(false);

      const features = map.current.queryRenderedFeatures({ layers: ['grid-fill-highlight'] });

      for (const tile of features) {
        boughtTilesIcons.current.features.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [tile.properties.centerLng, tile.properties.centerLat],
          },
          properties: {
            name: user.firstName.charAt(0).toUpperCase() + user.lastName.charAt(0).toUpperCase(),
            color: stringToHslColor(
              user.firstName.charAt(0).toUpperCase() + user.lastName.charAt(0).toUpperCase(),
              40,
              40,
            ),
          },
        });
      }

      map.current.getSource('points').setData(boughtTilesIcons.current);

      boughtTiles.current = [...boughtTiles.current, ...selectedTiles.current];
      map.current.setFilter('grid-fill-bought', ['in', 'index', ...boughtTiles.current]);
      if (user.referralUserId) {
        dispatch(decreaseUserBalance(tilePrice.current * selectedTilesNumber * (1 - _REFERRAL_CODE_DISCOUNT)));
      } else {
        dispatch(decreaseUserBalance(tilePrice.current * selectedTilesNumber));
      }
      resetSelectedTiles();
      modalRef.current.hide();
      dispatch(buyLandReset());
      dispatch(fetchUserInfo());
    }
  }, [buyLandAction.success, dispatch]);

  useEffect(() => {
    if (setLandPartOnSaleAction.success) {
      setLoading(false);
      resetSelectedTiles();
      modalSellLandRef.current.hide();
      dispatch(setLandPartOnSaleReset());
    }
  }, [setLandPartOnSaleAction.success, dispatch]);

  const initListeners = useCallback(() => {
    map.current.on('load', function () {
      canvas.current = map.current.getCanvasContainer();

      addSource();
      addLayers();

      map.current.on('click', 'grid-fill', onFirstClick);

      document.getElementById('geocoder')?.appendChild(geocoder.onAdd(map.current));

      map.current.on('style.load', () => {
        addSource();
        addLayers();
        map.current.setFilter('grid-fill-highlight', ['in', 'index', ...selectedTiles.current]);
      });

      setLng(map.current.getCenter().lng);
      setLat(map.current.getCenter().lat);
      setZoom(map.current.getZoom());
    });
    map.current.on('move', () => {
      setLng(map.current.getCenter().lng);
      setLat(map.current.getCenter().lat);
      setZoom(map.current.getZoom());
    });
    map.current.on('moveend', () => {
      getVisibleTilesInfo();
    });
  }, []);

  const addSource = useCallback(() => {
    map.current.addSource('grid', {
      type: 'vector',
      url: 'mapbox://expansionunlimited.4vg0r3y2',
    });

    map.current.addSource('points', {
      type: 'geojson',
      data: boughtTilesIcons.current,
    });
  }, []);

  const addLayers = useCallback(() => {
    map.current.addLayer({
      id: 'grid-fill',
      type: 'fill',
      source: 'grid',
      'source-layer': 'grid_full-2kphoy',
      paint: {
        'fill-color': 'rgba(0, 0, 1, 0.2)',
        'fill-outline-color': 'rgba(0, 0, 1, 0.2)',
      },
      minzoom: 16.5,
    });

    map.current.addLayer({
      id: 'grid-fill-highlight',
      type: 'fill',
      source: 'grid',
      'source-layer': 'grid_full-2kphoy',
      paint: {
        'fill-color': 'rgba(0, 0, 1, 0.5)',
        'fill-outline-color': 'rgba(0, 0, 1, 0.5)',
      },
      filter: ['in', 'index'],
      minzoom: 16.5,
    });

    map.current.addLayer({
      id: 'grid-fill-bought',
      type: 'fill',
      source: 'grid',
      'source-layer': 'grid_full-2kphoy',
      paint: {
        'fill-opacity': 0,
      },
      filter: ['in', 'index', ...boughtTiles.current],
      minzoom: 16.5,
    });

    map.current.addLayer({
      id: 'icon',
      type: 'circle',
      source: 'points',
      paint: {
        'circle-radius': {
          base: 1.75,
          stops: [
            [16.5, 13],
            [22, 450],
          ],
        },
        'circle-color': ['get', 'color'],
        'circle-opacity': 0.95,
      },
      minzoom: 16.5,
    });

    // Add a symbol layer
    map.current.addLayer({
      id: 'points',
      type: 'symbol',
      source: 'points',
      layout: {
        'text-field': ['get', 'name'],
      },
      paint: {
        'text-color': '#ffffff',
      },
      minzoom: 16.5,
    });

    map.current.addLayer({
      id: 'grid-line',
      type: 'line',
      source: 'grid',
      'source-layer': 'grid_full-2kphoy',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#000',
        'line-width': 1,
      },
      minzoom: 16.5,
    });
  }, []);

  const getVisibleTilesInfo = useCallback(async () => {
    const visibleTiles = map.current.queryRenderedFeatures({
      layers: ['grid-fill'],
    });

    boughtTilesIcons.current.features = [];

    const tiles = visibleTiles.map(feature => feature.properties.index);
    if (tiles.length > 0) {
      try {
        const response = await tileService.fetchTilesStatus(tiles);

        const filteredTiles = visibleTiles.filter(tile =>
          Object.keys(response).includes(tile.properties.index.toString()),
        );

        for (const tile of filteredTiles) {
          boughtTilesIcons.current.features.push({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [tile.properties.centerLng, tile.properties.centerLat],
            },
            properties: {
              name: response[tile.properties.index],
              color: stringToHslColor(response[tile.properties.index], 40, 40),
            },
          });
        }

        map.current.getSource('points').setData(boughtTilesIcons.current);
        boughtTiles.current = Object.keys(response).map(key => parseInt(key));

        map.current.setFilter('grid-fill-bought', ['in', 'index', ...boughtTiles.current]);
      } catch (err) {
        console.log(err);
      }
    }
  }, []);

  const onFirstClick = useCallback(e => {
    startPosition.current = mousePos(e.originalEvent);
    const bbox = [startPosition.current, startPosition.current];
    const clickedFeature = map.current.queryRenderedFeatures(bbox, {
      layers: ['grid-fill'],
    });
    // TODO: if clicked on bought tile

    if (selectedTiles.current.length === 0) {
      if (boughtLand.current) {
        if (boughtLand.current.tiles.some(tile => tile.mapId === clickedFeature[0].properties.index)) {
          map.current.off('click', 'grid-fill', onFirstClick);
          map.current.on('mousemove', 'grid-fill', onMouseMoveBoughtTiles);
          map.current.on('click', 'grid-fill', onSecondClick);
        }
        return;
      } else {
        if (boughtTiles.current.includes(clickedFeature[0].properties.index)) {
          const tileId = clickedFeature[0].properties.index;
          console.log(`Tile ${clickedFeature[0].properties.index} has been bought`);
          const land = user.lands.find(land => land.tiles.some(tile => tile.mapId === tileId));
          console.log(land);
          if (land) {
            console.log('kliknuo na svoj');
            boughtLand.current = land;
            map.current.off('click', 'grid-fill', onFirstClick);
            map.current.on('mousemove', 'grid-fill', onMouseMoveBoughtTiles);
            map.current.on('click', 'grid-fill', onSecondClick);
            if (tilePrice.current === 0) {
              tileLng.current = e.lngLat.lng;
              tileLat.current = e.lngLat.lat;
            }
            return;
          } else {
            return;
          }
        }
      }
    } else {
      if (boughtTiles.current.includes(clickedFeature[0].properties.index)) {
        console.log(`Tile ${clickedFeature[0].properties.index} has been bought`);
        return;
      }
    }

    // TODO: if clicked on selected tile
    if (selectedTiles.current.includes(clickedFeature[0].properties.index)) {
      console.log(`Tile ${clickedFeature[0].properties.index} has been selected`);
      return;
    }

    map.current.off('click', 'grid-fill', onFirstClick);
    map.current.on('mousemove', 'grid-fill', onMouseMove);
    map.current.on('click', 'grid-fill', onSecondClick);
    if (tilePrice.current === 0) {
      tileLng.current = e.lngLat.lng;
      tileLat.current = e.lngLat.lat;
    }
  }, []);

  const onSecondClick = useCallback(async e => {
    console.log('Second');

    const clickedTile = map.current.queryRenderedFeatures(e.point);
    let tileFeature = clickedTile.find(tile => tile.layer.id === 'grid-fill');

    let features = map.current.queryRenderedFeatures({ layers: ['grid-fill-highlight'] });
    const options = { units: 'kilometers' };

    const bbox = [
      Math.min(tileLng.current, e.lngLat.lng),
      Math.min(tileLat.current, e.lngLat.lat),
      Math.max(tileLng.current, e.lngLat.lng),
      Math.max(tileLat.current, e.lngLat.lat),
    ];

    if (selectedTiles.current.length > 0) {
      const selectedFeatures = features.filter(tile => selectedTiles.current.includes(tile.properties.index));
      const newSelectedFeatures = features.filter(tile => !selectedTiles.current.includes(tile.properties.index));

      let hasNeighbor = false;
      for (const tile of selectedFeatures) {
        for (const newTile of newSelectedFeatures) {
          const distance =
            turfDistance(
              turfPoint([newTile.properties.centerLng, newTile.properties.centerLat]),
              turfPoint([tile.properties.centerLng, tile.properties.centerLat]),
              options,
            ) * 1000;
          if (distance.toFixed(4) <= 20) {
            hasNeighbor = true;
          }
        }
      }

      if (!hasNeighbor) {
        return;
      }
    }

    if (boughtLand.current) {
      if (boughtLand.current.tiles.some(tile => tile.mapId === tileFeature.properties.index)) {
        console.log('kliknuo na tile koji pripada lendu');

        if (selectedBoughtTiles.current.length > 0) {
          const selectedFeatures = features.filter(tile => selectedBoughtTiles.current.includes(tile.properties.index));
          const newSelectedFeatures = features.filter(
            tile => !selectedBoughtTiles.current.includes(tile.properties.index),
          );

          let hasNeighbor = false;
          for (const tile of selectedFeatures) {
            for (const newTile of newSelectedFeatures) {
              const distance =
                turfDistance(
                  turfPoint([newTile.properties.centerLng, newTile.properties.centerLat]),
                  turfPoint([tile.properties.centerLng, tile.properties.centerLat]),
                  options,
                ) * 1000;
              if (distance.toFixed(4) <= 20) {
                hasNeighbor = true;
              }
            }
          }

          if (!hasNeighbor) {
            return;
          }
        }

        map.current.off('mousemove', 'grid-fill', onMouseMoveBoughtTiles);
        map.current.off('click', 'grid-fill', onSecondClick);
        map.current.on('click', 'grid-fill', onFirstClick);

        let currentSelectedTiles = map.current.getFilter('grid-fill-highlight');

        currentSelectedTiles = currentSelectedTiles.splice(2, currentSelectedTiles.length - 2);

        const uniqueSelectedTiles = currentSelectedTiles.filter(onlyUnique);
        selectedBoughtTiles.current = uniqueSelectedTiles;
        setSelectedTilesNumber(selectedBoughtTiles.current.length);
        if (tilePrice.current === 0) {
          tilePrice.current = boughtLand.current.boughtValue / boughtLand.current.tiles.length;
          setDistrict(boughtLand.current.district);
          setRegion(boughtLand.current.state);
          setCountry(boughtLand.current.country);
          await axios
            .get(
              `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/[${bbox}]/400x400?access_token=${mapboxgl.accessToken}&logo=false`,
              { responseType: 'arraybuffer' },
            )
            .then(response => {
              let blob = new Blob([response.data], { type: response.headers['content-type'] });
              landImage.current = new File([blob], 'name.jpeg');
            });
        }
        return;
      } else {
        return;
      }
    }

    map.current.off('mousemove', 'grid-fill', onMouseMove);
    map.current.off('click', 'grid-fill', onSecondClick);
    map.current.on('click', 'grid-fill', onFirstClick);

    if (tilePrice.current === 0) {
      setLoadingPrice(true);

      const response1 = axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${tileLng.current},${tileLat.current}.json?access_token=${mapboxgl.accessToken}`,
      );
      const response2 = axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${e.lngLat.lng},${tileLat.current}.json?access_token=${mapboxgl.accessToken}`,
      );
      const response3 = axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${tileLng.current},${e.lngLat.lat}.json?access_token=${mapboxgl.accessToken}`,
      );
      const response4 = axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${e.lngLat.lng},${e.lngLat.lat}.json?access_token=${mapboxgl.accessToken}`,
      );

      await axios
        .get(
          `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/[${bbox}]/400x400?access_token=${mapboxgl.accessToken}&logo=false`,
          { responseType: 'arraybuffer' },
        )
        .then(response => {
          let blob = new Blob([response.data], { type: response.headers['content-type'] });
          landImage.current = new File([blob], 'name.jpeg');
        });

      let districts = [];
      let regions = [];
      let countries = [];

      await Promise.all([response1, response2, response3, response4]).then(function (values) {
        values.forEach(value => {
          value.data.features.forEach(feature => {
            switch (feature.id.split('.')[0]) {
              case 'district':
                districts.push(feature.text);
                break;
              case 'region':
                regions.push(feature.text);
                break;
              case 'country':
                countries.push(feature.text);
                break;
              default:
                break;
            }
          });
        });
      });

      districts = [...new Set(districts)];
      regions = [...new Set(regions)];
      countries = [...new Set(countries)];

      let requestData = { countries: countries, states: regions, districts: districts };

      try {
        const priceInfo = await tileService.fetchTilePrice(requestData);
        tilePrice.current = priceInfo.value;
        setDistrict(priceInfo.district);
        setRegion(priceInfo.state);
        setCountry(priceInfo.country);
      } catch (err) {
        console.log(err);
        setLoadingPrice(false);
      }
    }

    setLoadingPrice(false);

    let currentSelectedTiles = map.current.getFilter('grid-fill-highlight');

    currentSelectedTiles = currentSelectedTiles.splice(2, currentSelectedTiles.length - 2);

    const uniqueSelectedTiles = currentSelectedTiles.filter(onlyUnique);
    selectedTiles.current = uniqueSelectedTiles;
    setSelectedTilesNumber(selectedTiles.current.length);
  }, []);

  const onMouseMove = useCallback(e => {
    selectedBbox.current = [startPosition.current, mousePos(e.originalEvent)];
    const features = map.current.queryRenderedFeatures(selectedBbox.current, {
      layers: ['grid-fill'],
    });

    let uniqueSelectedTilesIndexes = [...selectedTiles.current];
    features.forEach(feature => {
      if (
        !boughtTiles.current.includes(feature.properties.index) &&
        !selectedTiles.current.includes(feature.properties.index)
      ) {
        uniqueSelectedTilesIndexes.push(feature.properties.index);
      }
    });

    if (uniqueSelectedTilesIndexes.length > MAX_SELECTED_TILES) {
      return;
    }

    map.current.setFilter('grid-fill-highlight', ['in', 'index', ...uniqueSelectedTilesIndexes]);
  }, []);

  const onMouseMoveBoughtTiles = useCallback(e => {
    selectedBbox.current = [startPosition.current, mousePos(e.originalEvent)];
    const features = map.current.queryRenderedFeatures(selectedBbox.current, {
      layers: ['grid-fill'],
    });

    let uniqueSelectedBoughtTilesIndexes = [...selectedBoughtTiles.current];
    features.forEach(feature => {
      if (
        boughtLand.current.tiles.some(tile => tile.mapId === feature.properties.index) &&
        !selectedBoughtTiles.current.includes(feature.properties.index)
      ) {
        uniqueSelectedBoughtTilesIndexes.push(feature.properties.index);
      }
    });

    if (uniqueSelectedBoughtTilesIndexes.length > MAX_SELECTED_TILES) {
      return;
    }

    map.current.setFilter('grid-fill-highlight', ['in', 'index', ...uniqueSelectedBoughtTilesIndexes]);
  }, []);

  const mousePos = e => {
    var rect = canvas.current.getBoundingClientRect();
    return new mapboxgl.Point(
      e.clientX - rect.left - canvas.current.clientLeft,
      e.clientY - rect.top - canvas.current.clientTop,
    );
  };

  const switchLayer = layer => {
    let layerValue = layer.target.value;
    map.current.setStyle('mapbox://styles/mapbox/' + layerValue);
    setMapSyle(layerValue);
  };

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const resetSelectedTiles = () => {
    console.log('resetovao');
    selectedTiles.current = [];
    selectedBoughtTiles.current = [];
    setSelectedTilesNumber(selectedTiles.current.length);
    setDistrict();
    setRegion();
    setCountry();
    tileLat.current = null;
    tileLng.current = null;
    tilePrice.current = 0;
    boughtLand.current = null;
    landImage.current = null;
    setError();
    map.current.setFilter('grid-fill-highlight', ['in', 'index']);
  };

  const buy = () => {
    if (!loggedIn) {
      history.push('/auth/sign-in');
    } else {
      modalRef.current.show();
    }
  };

  const sell = () => {
    if (!loggedIn) {
      history.push('/auth/sign-in');
    } else {
      modalSellLandRef.current.show();
    }
  };

  const isConfirmButtonDisabled = () => {
    if (landName.trim().length) {
      return false;
    } else {
      return true;
    }
  };

  const isSellingButtonDisabled = () => {
    if (landName.trim().length && sellingPrice.trim().length && !isNaN(+sellingPrice) && +sellingPrice > 0) {
      return false;
    } else {
      return true;
    }
  };

  const confirmBuyLand = () => {
    if (!loggedIn) {
      history.push('/auth/sign-in');
    } else {
      setLoading(true);
      let formData = new FormData();

      const totalPrice = tilePrice.current * selectedTilesNumber;
      const tiles = selectedTiles.current;

      formData.append('file', landImage.current);
      formData.append('country', country);
      formData.append('district', district);
      formData.append('state', region);
      formData.append('boughtValue', totalPrice);
      tiles.forEach(tile => {
        formData.append('tiles[]', tile);
      });
      formData.append('x', tileLat.current);
      formData.append('y', tileLng.current);
      formData.append('landName', landName);

      dispatch(buyLand(formData));
    }
  };

  const confirmSellLand = () => {
    if (!loggedIn) {
      history.push('/auth/sign-in');
    } else {
      setLoading(true);
      let formData = new FormData();

      const totalPrice = tilePrice.current * selectedTilesNumber;
      const tiles = selectedBoughtTiles.current;

      formData.append('file', landImage.current);
      formData.append('id', boughtLand.current.id);
      formData.append('country', country);
      formData.append('district', district);
      formData.append('state', region);
      formData.append('boughtValue', totalPrice);
      tiles.forEach(tile => {
        formData.append('tiles[]', tile);
      });
      formData.append('sellingPrice', sellingPrice);
      formData.append('x', tileLat.current);
      formData.append('y', tileLng.current);
      formData.append('landName', landName);

      dispatch(setLandPartOnSale(formData));
    }
  };

  return (
    <div className="map__screen">
      <div className="map__screen__header">
        <div id="geocoder" className="geocoder" />
        <div>
          <RadioButton
            label="Map"
            id="map"
            onClick={switchLayer}
            value="streets-v11"
            checked={mapSyle === 'streets-v11'}
          />
          <RadioButton
            label="Satelite"
            id="satelite"
            onClick={switchLayer}
            value="satellite-v9"
            checked={mapSyle === 'satellite-v9'}
          />
          <RadioButton label="Dark" id="dark" onClick={switchLayer} value="dark-v10" checked={mapSyle === 'dark-v10'} />
        </div>
      </div>
      <div className="map__screen__content">
        <div className="map__screen__content__marketplace__container">
          <div style={{ paddingLeft: '1rem' }}>
            <SelectInput
              id="mycountry"
              label="Country"
              currentValue={countrySearch}
              setOption={setCountrySearch}
              options={countrySearchOptions}
            />
            <SelectInput
              id="mystate"
              label="State"
              currentValue={stateSearch}
              setOption={setStateSearch}
              options={stateSearchOptions}
            />
            <SelectInput
              id="mycounty"
              label="County"
              currentValue={districtSearch}
              setOption={setDistrictSearch}
              options={districtSearchOptions}
            />
          </div>
          <div className="map__screen__content__marketplace__container__table">
            <div className="map__screen__content__marketplace__container__table__header">
              <div className="map__screen__content__marketplace__container__table__col">
                <p className="map__screen__content__marketplace__container__table__col-text">State</p>
              </div>
              <div className="map__screen__content__marketplace__container__table__col">
                <p className="map__screen__content__marketplace__container__table__col-text">County</p>
              </div>
              <div className="map__screen__content__marketplace__container__table__col">
                <p className="map__screen__content__marketplace__container__table__col-text">Tiles</p>
              </div>
              <div className="map__screen__content__marketplace__container__table__col">
                <p className="map__screen__content__marketplace__container__table__col-text">Seller</p>
              </div>
              <div className="map__screen__content__marketplace__container__table__col">
                <p className="map__screen__content__marketplace__container__table__col-text">PPT</p>
              </div>
              <div className="map__screen__content__marketplace__container__table__col"></div>
            </div>
            {lands.map(item => (
              <MarketplaceItem key={item.id} item={item} />
            ))}
          </div>
          <div style={{ flex: 1 }} />
          <div className="map__screen__content__pagination__container">
            <Pagination
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={perPage}
              onPageChange={page => setPage(page)}
            />
          </div>
        </div>
        <div ref={mapContainer} className="map__screen__content__map__container" />
        <div className="selectionInfo">
          <div className="selectionInfoHeader">
            <p className="selectionInfoText">Selection Info</p>
            <p className="clear" onClick={resetSelectedTiles}>
              CLEAR
            </p>
          </div>
          <div className="tilesSelectedContainer">
            <div className="tilesSelected">
              <img src={Icons.grid} alt="grid" />
              <p className="tilesSelectedText">Tiles Selected</p>
            </div>
            <p className="tilesSelectedNumber">{selectedTilesNumber}/750</p>
          </div>
          <div className="devider" />
          {loadingPrice && <Loader />}
          {selectedTilesNumber > 0 && (
            <>
              <div className="tileInfo">
                <p className="tileInfoHeader">{country}</p>
                <p className="tileInfoText">{region}</p>
                <p className="tileInfoText">{district}</p>
                {tileLng.current && tileLat.current && (
                  <p className="tileInfoText">
                    {tileLng.current.toFixed(5)}, {tileLat.current.toFixed(5)}
                  </p>
                )}
              </div>
              <div className="priceContainer">
                <div className="priceRow">
                  <p className="priceRowText">Price per tile</p>
                  <p className="priceRowNumber">${tilePrice.current.toFixed(2)}</p>
                </div>
                <div className="priceRow">
                  <p className="priceRowText">Price</p>
                  <p className="priceRowNumber">${(tilePrice.current * selectedTilesNumber).toFixed(2)}</p>
                </div>
                {user.referralUserId && (
                  <div className="priceRow">
                    <p className="priceRowText" style={{ color: '#2fae3f' }}>
                      With discount
                    </p>
                    <p className="priceRowNumber" style={{ color: '#2fae3f' }}>
                      ${(tilePrice.current * selectedTilesNumber * (1 - _REFERRAL_CODE_DISCOUNT)).toFixed(2)}
                    </p>
                  </div>
                )}
              </div>
            </>
          )}
          {selectedTilesNumber > 0 &&
            (boughtLand.current ? (
              <Button className="buy" title="Sell" onClick={sell} />
            ) : (
              <Button className="buy" title="Buy" onClick={buy} />
            ))}
        </div>
      </div>
      <Modal ref={modalRef} onClose={() => modalRef.current.hide()} title="Buy land" maxWidth="500px">
        <p className="land__screen__main__container__price__container__modal-text">
          Enter the land name and confirm the purchase!
        </p>
        <Input
          type="text"
          className="land__screen__main__container__price__container__modal-input"
          onChange={setLandName}
          placeholder="Land name"
          disabled={loading}
        />
        <div style={{ color: 'white', marginTop: '1rem', fontSize: 14, opacity: 0.9 }}>{error}</div>
        <div className="land__screen__main__container__price__container__modal__button__container">
          <Button
            title="Cancel"
            onClick={() => modalRef.current.hide()}
            style={{ marginRight: '2rem' }}
            className="land__screen__main__container__price__container__modal__button__container-btn"
          />
          <Button
            disabled={isConfirmButtonDisabled() || loading}
            title={loading ? 'Buying...' : 'Buy'}
            onClick={confirmBuyLand}
            className="land__screen__main__container__price__container__modal__button__container-btn"
          />
        </div>
      </Modal>

      <Modal ref={modalSellLandRef} onClose={() => modalSellLandRef.current.hide()} title="Sell land" maxWidth="500px">
        <p className="land__screen__main__container__price__container__modal-text">
          Warning: Your land will be divided into two parts.
        </p>
        <p className="land__screen__main__container__price__container__modal-text">Enter the name for new land!</p>
        <Input
          type="text"
          className="land__screen__main__container__price__container__modal-input"
          onChange={setLandName}
          placeholder="Land name"
          disabled={loading}
        />
        <div style={{ height: 20 }} />
        <Input
          type="text"
          className="land__screen__main__container__price__container__modal-input"
          onChange={setSellingPrice}
          placeholder="Selling price"
          disabled={loading}
        />
        <div style={{ color: 'white', marginTop: '1rem', fontSize: 14, opacity: 0.9 }}>{error}</div>
        <div className="land__screen__main__container__price__container__modal__button__container">
          <Button
            title="Cancel"
            onClick={() => modalSellLandRef.current.hide()}
            style={{ marginRight: '2rem' }}
            className="land__screen__main__container__price__container__modal__button__container-btn"
          />
          <Button
            disabled={isSellingButtonDisabled() || loading}
            title={loading ? 'Selling...' : 'Sell'}
            onClick={confirmSellLand}
            className="land__screen__main__container__price__container__modal__button__container-btn"
          />
        </div>
      </Modal>
    </div>
  );
};

const MarketplaceItem = ({ item }) => {
  let page = +useLocation().search.split('=')[1].split('&')[0];

  const history = useHistory();
  return (
    <div className="map__screen__content__marketplace__container__table__header">
      <div className="map__screen__content__marketplace__container__table__col">
        <p className="map__screen__content__marketplace__container__table__col-value">{item.state}</p>
      </div>
      <div className="map__screen__content__marketplace__container__table__col">
        <p className="map__screen__content__marketplace__container__table__col-value">{item.district}</p>
      </div>
      <div className="map__screen__content__marketplace__container__table__col">
        <p className="map__screen__content__marketplace__container__table__col-value">{item.tiles}</p>
      </div>
      <div className="map__screen__content__marketplace__container__table__col">
        <p className="map__screen__content__marketplace__container__table__col-value">
          {item.user.firstName} {item.user.lastName}
        </p>
      </div>
      <div className="map__screen__content__marketplace__container__table__col">
        <p className="map__screen__content__marketplace__container__table__col-value">
          {(item.sellingPrice / item.tiles).toFixed(2)}
        </p>
      </div>
      <div className="map__screen__content__marketplace__container__table__col">
        <Button
          className="map__screen__content__marketplace__container__table__col-btn"
          onClick={() =>
            history.push({
              pathname: '/earth',
              search: `page=${page}&x=${item.coordinates.x}&y=${item.coordinates.y}`,
            })
          }
          title="View"
        />
        <Button
          className="map__screen__content__marketplace__container__table__col-btn map__screen__content__marketplace__container__table__col-black-btn"
          onClick={() => history.push(`land/${item.id}?page=1`)}
          title="Buy"
        />
      </div>
    </div>
  );
};

export default MapBox;
