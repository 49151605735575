import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from '../../store/actions/auth';
import { Button, Input, Modal } from '../../components';
import { toast } from 'react-toastify';
import { Images } from '../../themes';
import Resizer from 'react-image-file-resizer';
import {
  resetPassword,
  resetPasswordReset,
  updateProfile,
  updateProfileReset,
  fetchUserInfo,
  withdrawMoney,
  withdrawMoneyReset,
} from '../../store/actions/user';
import userService from '../../api/services/user';

const ProfileScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const imageInput = useRef();
  const resetPasswordModalRef = useRef();
  const withdrawMoneyModalRef = useRef();

  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const user = useSelector(state => state.userInfo);
  const updateProfileAction = useSelector(state => state.userInfo.updateProfile);
  const resetPasswordAction = useSelector(state => state.userInfo.resetPassword);
  const withdrawMoneyAction = useSelector(state => state.userInfo.withdrawMoney);

  const [username, setUsername] = useState(user.username);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [creditError, setCreditError] = useState('');

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [amount, setAmount] = useState(0);
  const [withdrawAmount, setWithdrawAmount] = useState(0);

  const [url, setUrl] = useState(null);
  const modalRef = useRef();

  const addCreditClick = async () => {
    try {
      setCreditError('');
      const url = await userService.getPaymentUrl({ amount: Number(amount) });
      setUrl(url);
      modalRef.current.show();
    } catch (error) {
      setCreditError(error.message);
    }
  };

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  const resetWorkModalInfo = () => {
    dispatch(fetchUserInfo());
    modalRef.current.hide();
  };

  const onLogoutClick = () => {
    dispatch(logout());
    history.push('/');
    window.scrollTo(0, 0);
  };

  const copyCode = () => {
    navigator.clipboard.writeText(user.referralCode);
    toast.success('Text copied!');
  };

  const resizeFile = file =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        200,
        200,
        'JPEG',
        100,
        0,
        uri => {
          resolve(uri);
        },
        'file',
      );
    });

  useEffect(() => {
    if (updateProfileAction.error) {
      toast.error(updateProfileAction.error.message);
      setLoading(false);
      dispatch(updateProfileReset());
    }
  }, [updateProfileAction, dispatch]);

  useEffect(() => {
    if (updateProfileAction.success) {
      toast.success('User profile successfully updated');
      setLoading(false);
      dispatch(updateProfileReset());
    }
  }, [updateProfileAction, dispatch]);

  useEffect(() => {
    if (resetPasswordAction.error) {
      toast.error(resetPasswordAction.error.message);
      dispatch(resetPasswordReset());
    }
  }, [resetPasswordAction, dispatch]);

  useEffect(() => {
    if (resetPasswordAction.success) {
      toast.success('Password successfully changed');
      resetPasswordModalRef.current.hide();
      setNewPassword('');
      setConfirmPassword('');
      setCurrentPassword('');
      dispatch(resetPasswordReset());
    }
  }, [resetPasswordAction, dispatch]);

  useEffect(() => {
    if (withdrawMoneyAction.error) {
      toast.error(withdrawMoneyAction.error.message);
      dispatch(withdrawMoneyReset());
    }
  }, [withdrawMoneyAction, dispatch]);

  useEffect(() => {
    if (withdrawMoneyAction.success) {
      dispatch(fetchUserInfo());
      toast.success('Money successfully withdrawn');
      withdrawMoneyModalRef.current.hide();
      setWithdrawAmount(0);
      dispatch(withdrawMoneyReset());
    }
  }, [withdrawMoneyAction, dispatch]);

  const onConfirmWithdrawMoneyClick = () => {
    dispatch(withdrawMoney(+withdrawAmount));
  };

  const onUploadProfileClick = () => {
    setLoading(true);
    toast.update('Upload in progress, please wait...');

    let formData = new FormData();

    if (imageFile !== null) {
      formData.append('file', imageFile);
    }

    formData.append('username', username);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);

    dispatch(updateProfile(formData));
  };

  const handleImagePreview = async e => {
    if (e.target.files.length !== 0) {
      let image_as_base64 = URL.createObjectURL(e.target.files[0]);
      let image_as_files = e.target.files[0];
      let image = await resizeFile(image_as_files);
      setImagePreview(image_as_base64);
      setImageFile(image);
    }
  };

  const onEditProfilePhotoClick = () => {
    imageInput.current.click();
  };

  const isResetPasswordBtnDisabled = () => {
    if (!currentPassword || !newPassword || !confirmPassword) {
      return true;
    }
  };

  const isDisabledWithdrawBtn = () => {
    if (withdrawAmount > 0) {
      return false;
    } else {
      return true;
    }
  };

  const isDisabledAddCreditBtn = () => {
    if (amount > 0) {
      return false;
    } else {
      return true;
    }
  };

  const onConfirmEditPasswordClick = () => {
    if (newPassword !== confirmPassword) {
      setError("Passwords doesn't match.");
      return;
    }

    setError('');
    dispatch(resetPassword({ currentPassword, newPassword, confirmPassword }));
  };

  return (
    <div className="profile__screen">
      <div className="profile__screen__main__container">
        <div className="myportfolio__screen__cover__photo__container">
          <img
            className="myportfolio__screen__cover__photo__container-cover__image"
            alt="coverPhoto"
            src={Images.planetHorizontal}
          />
          <img
            className="myportfolio__screen__cover__photo__container-image"
            alt="coverPhotoText"
            src={Images.coverPhotoText}
          />
        </div>
        <div className="profile__screen__main__container__content">
          <div className="profile__screen__main__container__content__left">
            <div className="profile__screen__main__container__content__left__image__container">
              <div className="profile__screen__main__container__content__left__image__container-avatar">
                {!user.profilePhoto ? (
                  <span className="profile__screen__main__container__content__left__image__container-text">
                    {user.firstName[0]}
                    {user.lastName[0]}
                  </span>
                ) : !imagePreview ? (
                  <img
                    className="profile__screen__main__container__content__left__image__container-profile__photo"
                    src={user.profilePhoto}
                    alt="user_profile_photo"
                  />
                ) : (
                  <img
                    className="profile__screen__main__container__content__left__image__container-profile__photo"
                    src={imagePreview}
                    alt="avatar_preview"
                  />
                )}
              </div>
              <div className="profile__screen__main__container__content__left__name__container">
                <p className="profile__screen__main__container__content__left__name__container-text">
                  {user.firstName} {user.lastName}
                </p>
                <input
                  ref={imageInput}
                  type="file"
                  className="auth__screen__container__avatar__container-image__input"
                  onChange={handleImagePreview}
                />
                <p
                  className="profile__screen__main__container__content__left__name__container-edit__photo"
                  onClick={onEditProfilePhotoClick}>
                  Edit photo
                </p>
              </div>
            </div>
            <Input
              placeholder="Username"
              value={username}
              onChange={setUsername}
              className="profile__screen__main__container__content__left-input"
            />
            <Input
              placeholder="First Name"
              value={firstName}
              onChange={setFirstName}
              className="profile__screen__main__container__content__left-input"
            />
            <Input
              placeholder="Last name"
              value={lastName}
              onChange={setLastName}
              className="profile__screen__main__container__content__left-input"
            />
            <Button
              title="Upload Profile"
              onClick={onUploadProfileClick}
              disabled={loading}
              className="profile__screen__main__container__content__left-reset__password_btn"
            />
            <Button
              title="Reset Password"
              onClick={() => resetPasswordModalRef.current.show()}
              className="profile__screen__main__container__content__left-reset__password_btn"
            />
            <Input
              placeholder="Referral Code"
              value={user.referralCode}
              className="profile__screen__main__container__content__left-referral__code__input"
              disabled
            />
            <Button
              title="Copy Code"
              onClick={copyCode}
              className="profile__screen__main__container__content__left-copy__code__btn"
            />
          </div>
          <div className="profile__screen__main__container__content__right">
            <div className="add__credit__container__content" style={{ paddingTop: '4rem' }}>
              <div className="add__credit__container__content__credit__info">
                <p className="add__credit__container__content__credit__info-amount">
                  ${parseFloat(user.balance).toFixed(2)}
                </p>
                <p className="add__credit__container__content__credit__info-credit">CREDIT</p>
              </div>
              <Input
                placeholder="Add Credit"
                value={amount}
                onChange={setAmount}
                className="add__credit__container__content-input"
                onFocus={() => setCreditError('')}
              />
              <Button
                title="Add Credit"
                onClick={addCreditClick}
                className="add__credit__container__content-button"
                style={{ height: '4rem' }}
                disabled={isDisabledAddCreditBtn()}
              />
              <div style={{ color: 'white' }}>{creditError}</div>

              <Input
                placeholder="Withdraw"
                value={withdrawAmount}
                onChange={setWithdrawAmount}
                className="profile__screen__main__container__content__left-referral__code__input"
              />
              <Button
                title="Withdraw"
                onClick={() => withdrawMoneyModalRef.current.show()}
                className="profile__screen__main__container__content__left-copy__code__btn"
                disabled={isDisabledWithdrawBtn()}
              />
              <Modal
                ref={withdrawMoneyModalRef}
                onClose={() => withdrawMoneyModalRef.current.hide()}
                title="Withdraw Money"
                maxWidth="500px">
                <p className="land__screen__main__container__price__container__modal-text">
                  Are you sure you want to withdraw ${withdrawAmount} from account?
                </p>
                <div className="land__screen__main__container__price__container__modal__button__container">
                  <Button
                    title="NO"
                    onClick={() => withdrawMoneyModalRef.current.hide()}
                    style={{ marginRight: '2rem' }}
                    className="land__screen__main__container__price__container__modal__button__container-btn"
                  />
                  <Button
                    title="YES"
                    onClick={onConfirmWithdrawMoneyClick}
                    className="land__screen__main__container__price__container__modal__button__container-btn"
                  />
                </div>
              </Modal>
            </div>
            <Modal ref={modalRef} title="Add Credit" maxWidth="60%" onClose={resetWorkModalInfo}>
              {url ? <iframe title="addcredit" src={url} width="100%" height="600px"></iframe> : null}
            </Modal>
          </div>
        </div>
        <Button
          onClick={onLogoutClick}
          title="Logout"
          className="profile__screen__main__container__content__left-reset__password_btn"
        />
      </div>
      <Modal
        ref={resetPasswordModalRef}
        onClose={() => resetPasswordModalRef.current.hide()}
        title="Reset Password"
        maxWidth="500px">
        <Input
          placeholder="Current Password"
          value={currentPassword}
          onChange={setCurrentPassword}
          type="password"
          onFocus={() => setError('')}
          className="profile__screen__main__container__content__left-input"
        />
        <Input
          placeholder="New Password"
          value={newPassword}
          onChange={setNewPassword}
          type="password"
          className="profile__screen__main__container__content__left-input"
          onFocus={() => setError('')}
        />
        <Input
          placeholder="Confirm Password"
          value={confirmPassword}
          type="password"
          onChange={setConfirmPassword}
          onFocus={() => setError('')}
          className="profile__screen__main__container__content__left-input"
        />
        <div style={{ color: 'white' }}>{error}</div>
        <div className="land__screen__main__container__price__container__modal__button__container">
          <Button
            title="Cancel"
            onClick={() => resetPasswordModalRef.current.hide()}
            style={{ marginRight: '2rem' }}
            className="land__screen__main__container__price__container__modal__button__container-btn"
          />
          <Button
            title="Confirm"
            disabled={isResetPasswordBtnDisabled()}
            onClick={onConfirmEditPasswordClick}
            className="land__screen__main__container__price__container__modal__button__container-btn"
          />
        </div>
      </Modal>
    </div>
  );
};

export default ProfileScreen;
