import {
  SET_INPUT_EMAIL,
  SIGN_UP_ATTEMPT,
  SIGN_UP_SUCCESS,
  SIGN_UP_ERROR,
  SIGN_UP_RESET,
  VERIFY_EMAIL_ATTEMPT,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_ERROR,
  VERIFY_EMAIL_RESET,
  VERIFY_PHONE_ATTEMPT,
  VERIFY_PHONE_SUCCESS,
  VERIFY_PHONE_ERROR,
  VERIFY_PHONE_RESET,
  RESEND_EMAIL_VERIFICATION_CODE_ATTEMPT,
  RESEND_EMAIL_VERIFICATION_CODE_SUCCESS,
  RESEND_EMAIL_VERIFICATION_CODE_ERROR,
  RESEND_EMAIL_VERIFICATION_CODE_RESET,
  RESEND_PHONE_VERIFICATION_CODE_RESET,
  RESEND_PHONE_VERIFICATION_CODE_ERROR,
  RESEND_PHONE_VERIFICATION_CODE_SUCCESS,
  RESEND_PHONE_VERIFICATION_CODE_ATTEMPT,
  SETUP_PHONE_ATTEMPT,
  SETUP_PHONE_SUCCESS,
  SETUP_PHONE_ERROR,
  SETUP_PHONE_RESET,
  SET_INPUT_PHONE,
  LOGOUT,
  LOGIN_ATTEMPT,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_RESET,
  SET_USERS_ALL_INFO,
  SEND_RESET_PASSWORD_EMAIL_ATTEMPT,
  SEND_RESET_PASSWORD_EMAIL_SUCCESS,
  SEND_RESET_PASSWORD_EMAIL_ERROR,
  SEND_RESET_PASSWORD_EMAIL_RESET,
  RESET_FORGOTTEN_PASSWORD_ATTEMPT,
  RESET_FORGOTTEN_PASSWORD_SUCCESS,
  RESET_FORGOTTEN_PASSWORD_ERROR,
  RESET_FORGOTTEN_PASSWORD_RESET,
} from '../constants';
import authService from '../../api/services/auth';
import localStorageService from '../../services/localStorageService';
import { LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_REFRESH_TOKEN } from '../../utils/constants';

export const signUpWithEmail = (email, password) => {
  return async dispatch => {
    try {
      dispatch({ type: SIGN_UP_ATTEMPT });
      await authService.signUpWithEmail(email, password);
      dispatch({ type: SIGN_UP_SUCCESS });
    } catch (err) {
      dispatch({ type: SIGN_UP_ERROR, payload: err });
    }
  };
};

export const signUpInfoReset = () => ({ type: SIGN_UP_RESET });

export const setInputEmail = email => ({ type: SET_INPUT_EMAIL, payload: email });

export const verifyEmail = (email, code) => {
  return async dispatch => {
    try {
      dispatch({ type: VERIFY_EMAIL_ATTEMPT });
      await authService.verifyEmail(email, code);
      dispatch({ type: VERIFY_EMAIL_SUCCESS });
    } catch (err) {
      dispatch({ type: VERIFY_EMAIL_ERROR, payload: err });
    }
  };
};

export const verifyEmailInfoReset = () => ({ type: VERIFY_EMAIL_RESET });

export const sendResetPasswordEmail = email => {
  return async dispatch => {
    try {
      dispatch({ type: SEND_RESET_PASSWORD_EMAIL_ATTEMPT });
      await authService.sendResetPasswordEmail(email);
      dispatch({ type: SET_INPUT_EMAIL, payload: email });
      dispatch({ type: SEND_RESET_PASSWORD_EMAIL_SUCCESS });
    } catch (err) {
      dispatch({ type: SEND_RESET_PASSWORD_EMAIL_ERROR, payload: err });
    }
  };
};

export const sendResetPasswordEmailReset = () => ({ type: SEND_RESET_PASSWORD_EMAIL_RESET });

export const resetForgottenPassword = (email, newPassword, code) => {
  return async dispatch => {
    try {
      dispatch({ type: RESET_FORGOTTEN_PASSWORD_ATTEMPT });
      await authService.resetPassword(email, newPassword, code);
      dispatch({ type: RESET_FORGOTTEN_PASSWORD_SUCCESS });
    } catch (err) {
      dispatch({ type: RESET_FORGOTTEN_PASSWORD_ERROR, payload: err });
    }
  };
};

export const resetForgottenPasswordReset = () => ({ type: RESET_FORGOTTEN_PASSWORD_RESET });

export const verifyPhone = (phone, code) => {
  return async dispatch => {
    try {
      dispatch({ type: VERIFY_PHONE_ATTEMPT });
      const { accessToken, refreshToken, user } = await authService.verifyPhone(phone, code);
      localStorageService.setItem(LOCAL_STORAGE_ACCESS_TOKEN, accessToken);
      localStorageService.setItem(LOCAL_STORAGE_REFRESH_TOKEN, refreshToken);
      dispatch({ type: SET_USERS_ALL_INFO, payload: user });
      dispatch({ type: VERIFY_PHONE_SUCCESS });
    } catch (err) {
      dispatch({ type: VERIFY_PHONE_ERROR, payload: err });
    }
  };
};

export const verifyPhoneInfoReset = () => ({ type: VERIFY_PHONE_RESET });

export const resendVerificationCodeEmail = email => {
  return async dispatch => {
    try {
      dispatch({ type: RESEND_EMAIL_VERIFICATION_CODE_ATTEMPT });
      await authService.resendVerificationCodeEmail(email);
      dispatch({ type: RESEND_EMAIL_VERIFICATION_CODE_SUCCESS });
    } catch (err) {
      dispatch({ type: RESEND_EMAIL_VERIFICATION_CODE_ERROR, payload: err });
    }
  };
};

export const resendVerificationCodeEmailInfoReset = () => ({ type: RESEND_EMAIL_VERIFICATION_CODE_RESET });

export const resendVerificatioCodePhone = phone => {
  return async dispatch => {
    try {
      dispatch({ type: RESEND_PHONE_VERIFICATION_CODE_ATTEMPT });
      await authService.resendVerificatioCodePhone(phone);
      dispatch({ type: RESEND_PHONE_VERIFICATION_CODE_SUCCESS });
    } catch (err) {
      dispatch({ type: RESEND_PHONE_VERIFICATION_CODE_ERROR, payload: err });
    }
  };
};

export const resendVerificatioCodePhoneInfoReset = () => ({ type: RESEND_PHONE_VERIFICATION_CODE_RESET });

export const setupPhone = phone => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SETUP_PHONE_ATTEMPT });
      const { auth } = getState();
      await authService.setupPhone(auth.inputEmail, phone);
      dispatch({ type: SETUP_PHONE_SUCCESS });
    } catch (err) {
      dispatch({ type: SETUP_PHONE_ERROR, payload: err });
    }
  };
};

export const setupPhoneInfoReset = () => ({ type: SETUP_PHONE_RESET });

export const setInputPhone = phone => ({ type: SET_INPUT_PHONE, payload: phone });

export const logout = () => {
  return async dispatch => {
    try {
      localStorageService.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
      localStorageService.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
      dispatch({ type: LOGOUT });
    } catch (err) {
      console.log('error: ', err);
    }
  };
};

export const loginWithEmail = (email, password) => {
  return async dispatch => {
    try {
      dispatch({ type: LOGIN_ATTEMPT });
      const phone = await authService.loginWithEmail(email, password);
      dispatch({ type: SET_INPUT_PHONE, payload: phone });
      dispatch({ type: LOGIN_SUCCESS });
    } catch (err) {
      dispatch({ type: LOGIN_ERROR, payload: err });
    }
  };
};

export const loginInfoReset = () => ({ type: LOGIN_RESET });
