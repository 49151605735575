import { combineReducers } from 'redux';

import authReducer from './auth';
import userReducer from './user';
import landReducer from './land';

export default combineReducers({
  auth: authReducer,
  userInfo: userReducer,
  land: landReducer,
});
