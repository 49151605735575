import React from 'react';
import { Images } from '../../../themes';

function ErrorScreen() {
  return (
    <div className="error__screen">
      <div className="error__screen__content">
        <img src={Images.planetVertical} alt="logo" />
        <p>Page Not Found</p>
      </div>
    </div>
  );
}

export default ErrorScreen;
