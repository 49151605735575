import React from 'react';
import { Icons } from '../../../themes';

function SplashScreen() {
  return (
    <div className="pulse__frame">
      <img className="pulse" src={Icons.eu} alt="pulse-logo" />
    </div>
  );
}

export default SplashScreen;
